/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/iframe-has-title */
import fetch from 'lib/fetchJson';
import Head from 'next/head';
import { UncontrolledPopover } from 'reactstrap';
import React, { Component, createRef, Fragment } from 'react';
import { API_URL } from 'env-config';
import { connect } from 'react-redux';
import Router, { withRouter } from 'next/router';
import { ToastContainer, toast } from 'react-toastify';
import { SWRConfig } from 'swr';
import Cookies from 'js-cookie';
import { signOut, updateDataUser } from 'actions/user';
import Link from 'next/link';
import { Form, Formik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import { Dialog, Transition } from '@headlessui/react';
import {
    makeRequestPostNoMasterUnitId, makeRequestGet, makeRequestPost, makeRequestPut,
} from 'utils/requests';
import moment from 'moment';
import YouTube from 'react-youtube';
import sjcl from 'sjcl';
import styles from './style/layout-exam.module.css';
import Loading from '../components/loading/loading';

const phoneRegExp = /((09|03|07|08|05)+([0-9]{8})\b)/g;
// const userNameRegExp = /^[^\s\\]*$/;
// const userNameRegExp = /[a-zA-Z0-9._@]+$/;
const userNameRegExp = /^[a-zA-Z0-9!@._-]{6,25}$/;
// const passWordRegExp = /^[^\s\\]*$/;
const passWordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
// const emailRegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const menus = [
    { name: 'TRANG CHỦ', current: false, link: '/trang-chu' },
    { name: 'VÀO THI', current: true, link: '/thi' },
    {
        name: 'TÌM HIỂU CUỘC THI', current: true, code: 'tin-tuc',
    },
    {
        name: 'DANH SÁCH DỰ THI', current: true, link: '/tra-cuu-diem-thi',
    },
    // { name: 'VÀO THI BẰNG MÃ CUỘC THI', current: true, link: '' },
    // { name: 'FAQ', current: true, link: '/trang-chu' },
];

const initialValues = {
    username: '',
    password: '',
};

const initialValuesForgotPass = {
    username1: '',
};

const validationSchemaForgotPass = yup.object().shape({
    username1: yup.string()
        .required('*Không được để trống.'),
});

const initialValuesCode = {
    examCode: '',
};

const validationSchema = yup.object().shape({
    username: yup.string()
        .required('*Không được để trống tên đăng nhập.'),
    password: yup.string()
        .required('*Không được để trống mật khẩu.'),
});

const validationSchemaJoinExam = yup.object().shape({
    examCode: yup.string()
        .required('*Không được để trống.'),
});

const initialValuesSignUp = {
    id: '',
    birthOfDate: '',
    className: '',
    username: '',
    fullName: '',
    gender: '',
    password: '',
    passwordAgain: '',
    phone: '',
    schoolId: '',
    schoolName: '',
    provinceId: '',
    districtId: '',
    grade: '',
};

const initialValuesSignUpInfo = {
    id: '',
    birthOfDate: '',
    className: '',
    username: '',
    fullName: '',
    gender: '',
    password: '',
    passwordAgain: '',
    phone: '',
    schoolId: '',
    schoolName: '',
    provinceId: '',
    districtId: '',
    grade: '',
    districtName: '',
    enabled: 0,
    provinceName: '',
    userId: 0,
};

const validationSchemaSignUpInfo = yup.object().shape({
    fullName: yup.string()
        .max(50, 'Too Long!')
        .required('Vui lòng nhập tên'),
    birthOfDate: yup.string()
        .required('Vui lòng chọn ngày sinh'),
    provinceId: yup.string()
        .required('Vui lòng chọn thành phố'),
    districtId: yup.string()
        .required('Vui lòng chọn quận'),
    schoolId: yup.string()
        .required('Vui lòng chọn trường'),
    grade: yup.string()
        .required('Vui lòng chọn khối'),
    className: yup.string()
        .required('Vui lòng nhập lớp'),
    phone: yup.string()
        .matches(phoneRegExp, '*Số điện thoại không đúng định dạng')
        .required('*Bạn chưa nhập Số điện thoại'),
});

const initialChangePassWord = {
    newPassword: '',
    oldPassword: '',
    confirmPassword: '',
};

const validationSchemaSignUp = yup.object().shape({
    fullName: yup.string()
        .max(50, 'Too Long!')
        .required('Vui lòng nhập tên'),
    birthOfDate: yup.string()
        .required('Vui lòng chọn ngày sinh'),
    password: yup.string()
        .required('Vui lòng nhập mật khẩu')
        .max(13, 'Mật khẩu không quá 13 ký tự!')
        .min(6, 'Mật khẩu ít nhất 6 kí tự!'),
    // .matches(passWordRegExp, 'Mật khẩu phải có 6 kí tự trở lên, có chữ hoa, chữ thường, chữ số và ký tự đặc biệt!'),
    passwordAgain: yup.string()
        .required('Vui lòng nhập lại mật khẩu')
        .test(
            'check',
            'Mật khẩu xác nhận không đúng',
            (value, params) => {
                const { password } = params.parent;
                if (password !== value) {
                    return false;
                }
                return true;
            },
        ),
    username: yup.string()
        .required('Vui lòng nhập tên đăng nhập')
        .max(25, 'Tên đăng nhập không quá 25 kí tự!')
        .min(6, 'Tên đăng nhập ít nhất 6 kí tự!')
        .matches(userNameRegExp, 'Tên đăng nhập không chứa khoảng trắng, ký tự đặc biệt'),
    provinceId: yup.string()
        .required('Vui lòng chọn thành phố'),
    districtId: yup.string()
        .required('Vui lòng chọn quận'),
    schoolId: yup.string()
        .required('Vui lòng chọn trường'),
    grade: yup.string()
        .required('Vui lòng chọn khối'),
    className: yup.string()
        .required('Vui lòng nhập lớp'),
    phone: yup.string()
        .matches(phoneRegExp, '*Số điện thoại không đúng định dạng')
        .required('*Bạn chưa nhập Số điện thoại'),
});

const validationSchemaSignUp2 = yup.object().shape({
    fullName: yup.string()
        .max(50, 'Too Long!')
        .required('Vui lòng nhập tên'),
    birthOfDate: yup.string()
        .required('Vui lòng chọn ngày sinh'),
    password: yup.string()
        .required('Vui lòng nhập mật khẩu')
        .max(13, 'Mật khẩu không quá 13 ký tự!')
        .min(6, 'Mật khẩu ít nhất 6 kí tự!'),
    // .matches(passWordRegExp, 'Mật khẩu không hợp lệ (không để khoảng trắng và dấu)'),
    passwordAgain: yup.string()
        .required('Vui lòng nhập lại mật khẩu')
        .max(13, 'Mật khẩu không quá 13 ký tự!')
        .min(6, 'Mật khẩu ít nhất 6 kí tự!'),
    // .matches(passWordRegExp, 'Mật khẩu không hợp lệ (không để khoảng trắng và dấu)'),
    username: yup.string()
        .required('Vui lòng nhập tên đăng nhập')
        .max(25, 'Tên đăng nhập không quá 25 kí tự!')
        .min(6, 'Tên đăng nhập ít nhất 6 kí tự!')
        .matches(userNameRegExp, 'Tên đăng nhập không chứa khoảng trắng, ký tự đặc biệt'),
    provinceId: yup.string()
        .required('Vui lòng chọn thành phố'),
    districtId: yup.string()
        .required('Vui lòng chọn quận'),
    schoolName: yup.string()
        .required('Vui lòng nhập trường'),
    grade: yup.string()
        .required('Vui lòng chọn khối'),
    className: yup.string()
        .required('Vui lòng nhập lớp'),
    phone: yup.string()
        .matches(phoneRegExp, '*Số điện thoại không đúng định dạng')
        .required('*Bạn chưa nhập Số điện thoại'),
});

class Layout extends Component {
    constructor() {
        super();
        this.state = {
            isModal: true,
            loading: false,
            isOpen: false,
            isModalSignUp: true,
            provinceData: [],
            provinceId: '',
            districtData: [],
            districtId: '',
            schoolData: [],
            isModalQuestion: true,
            twoQuestionArr: [],
            showPass: true,
            showPass2: true,
            isModalSyntax: true,
            modal: '',
            captchaResponse: '',
            toggleSchool: true,
            userName: '',
            dataError: false,
            modalForgotPass: true,
            userNameForgotPass: '',
            phoneForgotPass: null,
            usernameNotActivated: '',
            isModalChangePassWord: false,
            configDatas: [],
            addSchoolModal: true,
            openAccount: false,
            isNotiUserMax: false,
            isModalExamCode: true,
            isModalAddInfomation: true,
            errorMessageExamCode: '',
            time: {},
            seconds: 0,
            numberClick: 0,
        };

        // this.timer = 0;
        // this.startTimer = this.startTimer.bind(this);
        // this.countDown = this.countDown.bind(this);

        this.refFormik = createRef();
        this.refFormikSignUp = createRef();
        this.refFormikSignUpInfo = createRef();
        this.refFormikForgotPass = createRef();
        this.recaptcha = createRef();
        this.recaptcha2 = createRef();
        this.refFormikChangePassWord = createRef();
        this.refFormikCodeExam = createRef();
        this.handleCaptchaResponseChange = this.handleCaptchaResponseChange.bind(this);
    }

    componentDidMount() {
        // const { seconds } = this.state;
        // const timeLeftVar = this.secondsToTime(seconds);
        // this.setState({ time: timeLeftVar });
        // if (window !== 'undefined') {
        //     const objectTime = JSON.parse(localStorage.getItem('objectTime'));
        //     const numberClick = localStorage.getItem('click');
        //     if (objectTime) {
        //         this.setState({
        //             time: objectTime.time,
        //             seconds: objectTime.seconds,
        //             numberClick,
        //         }, () => this.startTimer());
        //     }
        // }

        const { router } = this.props;
        const jwt = Cookies.get('jwt');
        const userId = Cookies.get('userId');
        const username = Cookies.get('username');
        const fullName = Cookies.get('fullName');
        const role = Cookies.get('role');
        const schoolName = Cookies.get('schoolName');
        const examCode = localStorage.getItem('examCode');
        if (jwt !== undefined && jwt !== '') {
            const userInfo = {
                jwt,
                isAuthenticated: true,
                username: sjcl.decrypt(jwt, username),
                fullName: sjcl.decrypt(jwt, fullName),
                userId: Number(sjcl.decrypt(jwt, userId)),
                role: sjcl.decrypt(jwt, role),
                schoolName: sjcl.decrypt(jwt, schoolName),
            };
            const { dispatch } = this.props;
            dispatch(updateDataUser(userInfo));
        } else if (router && router.pathname !== '/tra-cuu-diem-thi') {
            if (examCode && examCode.trim()) return;
            this.handleClickSignOut();
        }
        this.recaptcha.reset();
        this.getDataConfig();
    }

    // // eslint-disable-next-line class-methods-use-this
    // secondsToTime(secs) {
    //     const hours = Math.floor(secs / (60 * 60));
    //     const divisorForMinutes = secs % (60 * 60);
    //     const minutes = Math.floor(divisorForMinutes / 60);
    //     const divisorForSeconds = divisorForMinutes % 60;
    //     const seconds = Math.ceil(divisorForSeconds);
    //     const obj = {
    //         h: hours,
    //         m: minutes,
    //         s: seconds,
    //     };
    //     return obj;
    // }

    // startTimer() {
    //     const { seconds } = this.state;
    //     if (this.timer === 0 && seconds > 0) {
    //         this.timer = setInterval(this.countDown, 1000);
    //     }
    // }

    // countDown() {
    // // Remove one second, set state so a re-render happens.
    //     const { seconds } = this.state;
    //     const newSeconds = seconds - 1;
    //     this.setState({
    //         time: this.secondsToTime(newSeconds),
    //         seconds: newSeconds,
    //     });
    //     localStorage.setItem('objectTime', JSON.stringify({
    //         time: this.secondsToTime(newSeconds),
    //         seconds: newSeconds,
    //     }));
    //     // Check if we're at zero.
    //     if (newSeconds === 0) {
    //         localStorage.setItem('click', 0);
    //         this.setState({
    //             numberClick: 0,
    //         });
    //         clearInterval(this.timer);
    //     }
    // }

    getDataConfig = () => {
        const urlDataConfig = `${API_URL}/api/configInfo/`;
        const dispatchArr = [
            makeRequestGet(urlDataConfig),
        ];

        Promise.all(dispatchArr)
            .then((res) => {
                const configDatas = res[0]?.data.data;
                // handle data config
                this.setState({
                    configDatas,
                });
            })
            .catch(() => false);
    }

    handleCaptchaResponseChange(response) {
        this.setState({
            captchaResponse: response,
        });
    }

    handlePreDataQuestion = () => {
        this.setState({ loading: true });
        const urlQuestion = `${API_URL}/api/surveyquestion/getQuestionSignup`;
        const dispatchArr = [
            makeRequestGet(urlQuestion),
        ];

        Promise.all(dispatchArr)
            .then((res) => {
                const quesionDatas = res[0]?.data.data;
                // handle data question
                let rdi;
                let randomIndex;
                let questionsLength = quesionDatas.length;
                if (questionsLength) {
                    // creates a loop that runs all selected questions
                    // eslint-disable-next-line no-plusplus
                    while (--questionsLength) {
                        randomIndex = Math.floor(Math.random() * (questionsLength + 1)); // get the index randomly
                        rdi = quesionDatas[randomIndex];
                        quesionDatas[randomIndex] = quesionDatas[questionsLength]; // Move index questionsLength to randomIndex
                        quesionDatas[questionsLength] = rdi; // Move back to rdi = index questionsLength
                    }

                    const cloneArr = quesionDatas.slice(); // copy the original array (quesionDatas)
                    const lengthCloneArr = cloneArr.length; // 9
                    const amount = 2; // 5: the number you want to get in the array
                    const left = lengthCloneArr - amount; // the remaining element has not been retrieved

                    cloneArr.splice(amount, left);
                    this.setState({
                        twoQuestionArr: cloneArr,
                        loading: false,
                    });
                }
            })
            .catch(() => false);
    };

    componentDidUpdate() {
    }

    getData = () => {
        const urlProvince = `${API_URL}/api/province`;
        const dispatchArr = [
            makeRequestGet(urlProvince),
        ];

        Promise.all(dispatchArr)
            .then((res) => {
                const provinceData = res[0]?.data.data;
                // handle data question
                this.setState({
                    provinceData,
                });
            })
            .catch(() => false);
    }

    getDistrictData = () => {
        const { provinceId } = this.state;
        const urlDistrict = `${API_URL}/api/district/${provinceId}`;
        const dispatchArr = [
            makeRequestGet(urlDistrict),
        ];

        Promise.all(dispatchArr)
            .then((res) => {
                const districtData = res[0]?.data.data;
                // handle data question
                this.setState({
                    districtData,
                });
            })
            .catch(() => false);
    }

    getSchoolData = () => {
        const { districtId } = this.state;
        const urlSchool = `${API_URL}/api/school/${districtId}`;
        const dispatchArr = [
            makeRequestGet(urlSchool),
        ];

        Promise.all(dispatchArr)
            .then((res) => {
                const schoolData = res[0]?.data.data;
                // handle data question
                this.setState({
                    schoolData,
                });
            })
            .catch(() => false);
    }

    handleSubmitSignin = (dataSubmit) => {
        const { username, password } = dataSubmit;
        const { dispatch } = this.props;
        const {
            isModal, captchaResponse,
        } = this.state;

        this.setState({ loading: true });
        const data = {
            username,
            password,
            captchaResponse,
        };
        this.setState({
            dataError: false,
        });
        const url = `${API_URL}/api/user/signin`;
        if (captchaResponse !== '') {
            makeRequestPostNoMasterUnitId(url, null, data).then(rs => {
                if (rs?.data?.error === true || rs?.data?.data?.role === 'ROLE_ADMIN' || rs?.data?.data?.role === 'ROLE_SUPPORT_ADMIN') {
                    if (rs?.data.data === 0) {
                        this.setState({
                            isNotiUserMax: true,
                        }, () => {
                            toast.error(rs?.data?.message);
                        });
                    } else {
                        this.setState({
                            isNotiUserMax: false,
                        }, () => {
                            toast.error(rs?.data?.message);
                        });
                    }
                    this.setState({
                        loading: false,
                    });
                    // window.grecaptcha.reset();
                } else if (rs?.data?.error === false && rs?.data?.data === null) {
                    this.setState({
                        dataError: true,
                        usernameNotActivated: username,
                        loading: false,
                    });
                    // window.grecaptcha.reset();
                } else {
                    toast.success('Đăng nhập thành công!');
                    this.setState({
                        isModal: !isModal,
                        loading: false,
                    });
                    const userInfo = {
                        jwt: rs?.data?.data?.token,
                        isAuthenticated: true,
                        username: rs?.data?.data?.username,
                        fullName: rs?.data?.data?.fullName,
                        userId: rs?.data?.data?.id,
                        role: rs?.data?.data?.role,
                        schoolName: rs?.data?.data?.schoolName,
                    };
                    dispatch(updateDataUser(userInfo));

                    Cookies.set('jwt', userInfo.jwt, { path: '/', secure: true });
                    Cookies.set('userId', sjcl.encrypt(userInfo.jwt, String(userInfo.userId)), { path: '/', secure: true });
                    Cookies.set('username', sjcl.encrypt(userInfo.jwt, userInfo.username), { path: '/', secure: true });
                    Cookies.set('fullName', sjcl.encrypt(userInfo.jwt, userInfo.fullName), { path: '/', secure: true });
                    Cookies.set('role', sjcl.encrypt(userInfo.jwt, userInfo.role), { path: '/', secure: true });
                    Cookies.set('schoolName', sjcl.encrypt(userInfo.jwt, userInfo.schoolName), { path: '/', secure: true });
                    // window.grecaptcha.reset();
                    Router.push('/');
                }
                window.grecaptcha.reset();
            }).catch(err => {
                switch (err?.response?.data?.status) {
                    case 400: {
                        break;
                    }
                    case 422:
                        break;
                    case 423:
                        break;
                    default:
                        break;
                }
                this.setState({ loading: false });
            });
        } else {
            toast.error('Bạn chưa chọn ô "Tôi không phải là người máy"');
            this.setState({ loading: false });
        }
    }

    handleClickSignOut = () => {
        const { dispatch } = this.props;

        const url = `${API_URL}/api/user/sign-out`;
        makeRequestGet(url).then(res => {
            if (res?.data?.error === false) {
                Cookies.remove('jwt', { path: '/' });
                Cookies.remove('username', { path: '/' });
                Cookies.remove('fullName', { path: '/' });
                Cookies.remove('role', { path: '/' });
                Cookies.remove('userId', { path: '/' });
                Cookies.remove('schoolName', { path: '/' });
                Router.push('/');
                dispatch(signOut());
            }
        }).catch((err) => {
            switch (err?.response?.data?.status) {
                case 400:
                case 403:
                case 404:
                    break;
                default:
                    break;
            }
        });
    }

    toggleCanMove = () => {
        toast.warning('Không thể thực hiện khi làm bài thi!');
    }

    toggleModal = () => {
        const { isModal } = this.state;
        this?.refFormik?.current?.handleReset();
        this?.refFormikForgotPass?.current?.handleReset();
        this.recaptcha.reset();
        this.setState({
            isModal: !isModal,
            loading: false,
            isModalSyntax: true,
            captchaResponse: '',
            dataError: false,
            modalForgotPass: true,
        });
    }

    toggleModalExamCode = () => {
        const { isModalExamCode } = this.state;
        this?.refFormikCodeExam?.current?.handleReset();
        this.setState({
            isModalExamCode: !isModalExamCode,
            errorMessageExamCode: '',
        });
    }

    handleCloseSignUp = () => {
        this.setState({
            isModalSyntax: true,
            userName: '',
        });
        this?.refFormikForgotPass?.current?.handleReset();
    }

    setIsOpen = () => {
        const { isOpen } = this.state;
        this.setState({
            isOpen: !isOpen,
        });
    }

    toggleModalSignUp = () => {
        this.getData();
        const { isModalSignUp } = this.state;
        this.refFormikSignUp.current.handleReset();
        this.recaptcha2?.current?.reset();
        this.setState({
            isModalSignUp: !isModalSignUp,
            isModal: true,
            captchaResponse: '',
        });
    }

    handleCancel = () => {
        this.refFormikSignUp.current.handleReset();
        this.recaptcha2?.current?.reset();
        this.setState({
            captchaResponse: '',
        });
    }

    handleSignUp = (dataSubmit) => {
        this.setState({
            loading: true,
        });
        const {
            birthOfDate, className, districtId, username, fullName, gender, password, passwordAgain, provinceId, schoolId,
            schoolName, grade, phone,
        } = dataSubmit;
        const { toggleSchool, captchaResponse } = this.state;
        this.setState({ loading: true });
        const date = moment(birthOfDate).format('DD/MM/YYYY');
        if (password !== passwordAgain) {
            toast.error('Mật khẩu xác nhận không trùng !');
            return false;
        }
        // get school name
        const urlSchool = `${API_URL}/api/school/${districtId}`;
        const dispatchArr = [
            makeRequestGet(urlSchool),
        ];
        Promise.all(dispatchArr)
            .then((res) => {
                const dataSchool = res[0]?.data.data;
                // handle data question
                let schoolN = '';
                if (toggleSchool) {
                    const schoolFilter = dataSchool.filter((id) => id.id === Number(schoolId));
                    schoolN = schoolFilter[0].name;
                } else {
                    schoolN = schoolName;
                }
                if (captchaResponse !== '') {
                    const data = {
                        birthOfDate: date,
                        className,
                        districtId,
                        username,
                        fullName,
                        gender,
                        password,
                        provinceId,
                        schoolId,
                        schoolName: schoolN,
                        grade,
                        captchaResponse,
                        phone,
                    };
                    const url = `${API_URL}/api/user/signup`;
                    makeRequestPost(url, null, data).then((rs) => {
                        if (rs.data.error === false) {
                            this.refFormik.current.handleReset();
                            this.recaptcha2?.current?.reset();
                            this.setState({
                                isModalSignUp: true,
                                isModalQuestion: false,
                                userName: username,
                                captchaResponse: '',
                                loading: false,
                            });
                        } else {
                            const warning = rs.data.message;
                            toast.warning(warning);
                            this.recaptcha2?.current?.reset();
                            this.setState({
                                captchaResponse: '',
                                loading: false,
                            });
                        }
                        this.setState({
                            loading: false,
                        });
                    }).catch((rs) => {
                        const warning = rs.message;
                        toast.warning(warning);
                        this.recaptcha2?.current?.reset();
                        this.setState({
                            captchaResponse: '',
                            loading: false,
                        });
                    });
                } else {
                    toast.error('Bạn chưa chọn ô "Tôi không phải là người máy"');
                }
            })
            .catch(() => {
                this.setState({
                    loading: false,
                });
            });
        this.handlePreDataQuestion();
    }

    handleAfterSignUp = () => {
        const { twoQuestionArr } = this.state;
        this.setState({
            isModalQuestion: true,
            isModalSyntax: false,
            modal: 'a',
            loading: true,
        });
        twoQuestionArr[0].answers = (this.refs.A0.checked ? 'A' : '') + (this.refs.B0.checked ? 'B' : '') + (this.refs.C0?.checked ? 'C' : '') + (this.refs.D0?.checked ? 'D' : '') + (this.refs.E0?.checked ? 'E' : '');
        twoQuestionArr[1].answers = (this.refs.A1.checked ? 'A' : '') + (this.refs.B1.checked ? 'B' : '') + (this.refs.C1?.checked ? 'C' : '') + (this.refs.D1?.checked ? 'D' : '') + (this.refs.E1?.checked ? 'E' : '');
        const url = `${API_URL}/api/surveyquestion/submit`;
        makeRequestPost(url, null, twoQuestionArr)
            .then(() => {
                this.setState({
                    loading: false,
                });
            })
            .catch(() => { });

        // twoQuestionArr.map((q) => {
        this.refs.A0.checked = false;
        this.refs.A1.checked = false;
        this.refs.B0.checked = false;
        this.refs.B1.checked = false;

        if (twoQuestionArr[0].answerC) {
            this.refs.C0.checked = false;
        }
        if (twoQuestionArr[1].answerC) {
            this.refs.C1.checked = false;
        }
        if (twoQuestionArr[0].answerD) {
            this.refs.D0.checked = false;
        }
        if (twoQuestionArr[1].answerD) {
            this.refs.D1.checked = false;
        }
        if (twoQuestionArr[0].answerE) {
            this.refs.E0.checked = false;
        }
        if (twoQuestionArr[1].answerE) {
            this.refs.E1.checked = false;
        }
        // });
        toast.success('Tạo tài khoản thành công!');
    }

    handleChooseProvince = (elm) => {
        const newValue = elm.target.value;
        const key = elm.target.name;
        this.refFormikSignUp.current.setValues({
            ...this.refFormikSignUp.current.values,
            [key]: newValue,
            districtId: '',
            schoolId: '',
        });
        this.refFormikSignUpInfo.current.setValues({
            ...this.refFormikSignUpInfo.current.values,
            [key]: newValue,
            districtId: '',
            schoolId: '',
        });
        this.setState({
            provinceId: newValue,
        }, () => {
            if (newValue === '') {
                this.setState({
                    districtData: [],
                    schoolData: [],
                });
            } else {
                this.getDistrictData();
            }
        });
    }

    handleChooseDistrict = (elm) => {
        const newValue = elm.target.value;
        const key = elm.target.name;
        this.refFormikSignUp.current.setValues({
            ...this.refFormikSignUp.current.values,
            [key]: newValue,
        });
        this.refFormikSignUpInfo.current.setValues({
            ...this.refFormikSignUpInfo.current.values,
            [key]: newValue,
        });
        this.setState({
            districtId: newValue,
        }, () => {
            if (newValue === '') {
                this.setState({
                    schoolData: [],
                });
            } else {
                this.getSchoolData();
            }
        });
    }

    handleChooseSchool = (elm) => {
        const newValue = elm.target.value;
        const key = elm.target.name;
        this.refFormikSignUp.current.setValues({
            ...this.refFormikSignUp.current.values,
            [key]: newValue,
        });
        this.refFormikSignUpInfo.current.setValues({
            ...this.refFormikSignUpInfo.current.values,
            [key]: newValue,
        });
    }

    handleChooseClass = (elm) => {
        const newValue = elm.target.value;
        const key = elm.target.name;
        this.refFormikSignUp.current.setValues({
            ...this.refFormikSignUp.current.values,
            [key]: newValue,
        });
        this.refFormikSignUpInfo.current.setValues({
            ...this.refFormikSignUpInfo.current.values,
            [key]: newValue,
        });
    }

    handleShowPass = (name) => {
        if (name === 'show') {
            this.setState({
                showPass: false,
            });
        } else if (name === 'show2') {
            this.setState({
                showPass2: false,
            });
        } else if (name === 'hide') {
            this.setState({
                showPass: true,
            });
        } else {
            this.setState({
                showPass2: true,
            });
        }
    }

    toggleUserInfo = () => {
        Router.push('/thong-tin-hoc-sinh');
    }

    toggleModalForgotPass = () => {
        this.setState({
            modalForgotPass: false,
            isModal: true,
        });
    }

    handleModalForgotPass = () => {
        const { isModalSyntax } = this.state;
        this.setState({
            isModalSyntax: !isModalSyntax,
            modal: 'b',
        });
    }

    handleSubmitForgotPass = (dataSubmit) => {
        const { username1 } = dataSubmit;
        const {
            isModalSyntax,
        } = this.state;

        this.setState({ loading: true });
        const data = {
            username1,
        };
        const url = `${API_URL}/api/user/checkUsername?username=${username1}`;
        makeRequestGet(url, null, data).then(rs => {
            if (rs?.data?.error === true) {
                toast.error(`Không tìm thấy tên đăng nhập ${username1}. Vui lòng kiểm tra lại`);
                this.setState({
                    loading: false,
                });
            } else if (rs?.data?.error === false && rs?.data?.data === null) {
                this.setState({
                    isModalSyntax: !isModalSyntax,
                    modal: 'c',
                    userNameForgotPass: username1,
                    loading: false,
                });
            } else if (rs?.data?.error === false && rs?.data?.data !== null) {
                this.setState({
                    isModalSyntax: !isModalSyntax,
                    modal: 'd',
                    userNameForgotPass: username1,
                    phoneForgotPass: rs?.data?.data.replace(/\[|\]/g, '').split(','),
                    loading: false,
                });
            }
        }).catch(err => {
            switch (err?.response?.data?.status) {
                case 400: {
                    break;
                }
                case 422:
                    break;
                case 423:
                    break;
                default:
                    break;
            }
            this.setState({ loading: false });
        });
    }

    handleCheckCode = (data) => {
        // this.timer = 0;
        // const numberClick = (+localStorage?.getItem('click') || 0) + 1;
        // localStorage.setItem('click', numberClick);
        // if (+numberClick >= 5) {
        //     this.setState({
        //         seconds: 1800,
        //         numberClick,
        //     }, () => this.startTimer());
        //     return;
        // }

        this.setState({
            loading: true,
            errorMessageExamCode: '',
        });
        const { examCode } = data;
        const url = `${API_URL}/api/exam/exam-code?examCode=${examCode}`;
        makeRequestPost(url).then(rs => {
            if (rs?.data?.error) {
                this.setState({
                    errorMessageExamCode: rs?.data?.message,
                });
            } else {
                this.setState({
                    isModalExamCode: true,
                });
                localStorage.setItem('examId', examCode);
                Router.push('/thi/thi-bang-ma-code');
                // localStorage.setItem('click', 0);
            }
            this.setState({
                loading: false,
            });
        }).catch(err => {
            switch (err?.response?.data?.status) {
                case 403: {
                    toast.error('Bạn chưa đăng nhập');
                    break;
                }
                case 422:
                    break;
                case 423:
                    break;
                default:
                    break;
            }
            this.setState({ loading: false });
        });
    }

    handleMouseOver = () => {
        const onMouseLeave = JSON.parse(localStorage.getItem('onMouseLeave'));
        if (onMouseLeave !== null) {
            // toast.warning('Em không được di chuyển con trỏ chuột ra ngoài phạm vi thi !');
        }
    }

    toggleSchool = () => {
        this.setState({
            addSchoolModal: false,
        });
    }

    onFocus = (e) => {
        e.currentTarget.type = 'date';
    }

    onBlur = (e) => {
        e.currentTarget.type = 'text';
        e.currentTarget.placeholder = 'Ngày sinh (dd/mm/yyyy)';
    }

    toggleModalPasswordChange = () => {
        const { isModalChangePassWord } = this.state;
        this.refFormikChangePassWord?.current?.handleReset();
        this.setState({
            isModalChangePassWord: !isModalChangePassWord,
        });
    }

    handleChangePassWord = (dataChangePassWord) => {
        const {
            oldPassword,
            newPassword,
            confirmPassword,
        } = dataChangePassWord;
        const data = {
            oldPassword,
            newPassword,
        };
        if (confirmPassword === newPassword) {
            const url = `${API_URL}/api/user/changePassword`;
            makeRequestPut(url, null, data).then(rs => {
                if (rs?.data?.error === false) {
                    const { dispatch } = this.props;
                    Cookies.remove('jwt', { path: '/' });
                    Cookies.remove('username', { path: '/' });
                    Cookies.remove('fullName', { path: '/' });
                    toast.success('Đổi mật khẩu thành công');
                    setTimeout(
                        () => {
                            Router.push('/');
                            dispatch(signOut());
                        },
                        1000,
                    );
                    this.setState({
                        isModal: false,
                    });
                } else {
                    toast.error('Sai mật khẩu hiện tại!');
                }
            })
                .catch(() => { });
        } else {
            toast.error('Mật khẩu xác nhận không khớp!');
        }
    }

    gotoExam = () => {
        const { isAuthenticated } = this.props;
        if (isAuthenticated) {
            Router.push('/thi');
        } else {
            this.toggleModal();
        }
    }

    renderChangePassWord = () => {
        const { isModalChangePassWord } = this.state;
        return (
            <Formik
                initialValues={initialChangePassWord}
                onSubmit={this.handleChangePassWord}
                validationSchema={yup.object().shape({
                    oldPassword: yup.string()
                        .required('*Bắt buộc'),
                    newPassword: yup.string()
                        .required('*Bắt buộc')
                        .max(13, 'Mật khẩu không quá 13 ký tự!')
                        .min(6, 'Mật khẩu trên 6 kí tự')
                        .matches(passWordRegExp, 'Mật khẩu phải có 6 kí tự trở lên, có chữ hoa, chữ thường, chữ số và ký tự đặc biệt!'),
                    confirmPassword: yup.string()
                        .required('Vui lòng nhập lại mật khẩu')
                        .test(
                            'check',
                            'Mật khẩu xác nhận không đúng',
                            (value, params) => {
                                const { newPassword } = params.parent;
                                if (newPassword !== value) {
                                    return false;
                                }
                                return true;
                            },
                        ),
                })}
                innerRef={this.refFormikChangePassWord}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props;
                    return (

                        <Transition.Root show={isModalChangePassWord} as={Fragment}>
                            <Dialog
                                as="div"
                                static
                                className="fixed z-10 inset-0 overflow-y-auto"
                                open={isModalChangePassWord}
                                onClose={this.toggleModalPasswordChange}
                            >
                                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                    </Transition.Child>

                                    {/* This element is to trick the browser into centering the modal contents. */}
                                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                                        &#8203;
                                    </span>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-1/4 ">
                                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                                <div className="sm:flex sm:items-start">
                                                    <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium mb-2">
                                                            Đổi mật khẩu
                                                        </Dialog.Title>
                                                        <hr />
                                                        <div className="mt-2">
                                                            <Form onSubmit={handleSubmit} className="py-4 flex justify-center items-center flex-col ">
                                                                <div className="w-full px-1 mb-6 pb-2 md:mb-0">
                                                                    <span className="italic">
                                                                        Mật khẩu cũ
                                                                        {' '}
                                                                        <span className="text-red-600">
                                                                            *
                                                                        </span>
                                                                        :
                                                                    </span>
                                                                    <input
                                                                        className="w-full h-8 mt-1 border rounded py-3 px-4 focus:outline-none focus:bg-white"
                                                                        id="oldPassword"
                                                                        type="text"
                                                                        name="oldPassword"
                                                                        value={values.oldPassword}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                    {errors.oldPassword && touched.oldPassword && (
                                                                        <div className="" style={{ color: 'red' }}>{errors.oldPassword}</div>
                                                                    )}
                                                                </div>

                                                                <div className="w-full px-1 mb-6 pb-2 md:mb-0">
                                                                    <span className="italic">
                                                                        Mật khẩu mới
                                                                        {' '}
                                                                        <span className="text-red-600">
                                                                            *
                                                                        </span>
                                                                        :
                                                                    </span>
                                                                    <input
                                                                        className="w-full h-8 mt-1 border rounded py-3 px-4 focus:outline-none focus:bg-white"
                                                                        id="newPassword"
                                                                        type="password"
                                                                        name="newPassword"
                                                                        value={values.newPassword}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                    {errors.newPassword && touched.newPassword && (
                                                                        <div className="" style={{ color: 'red' }}>{errors.newPassword}</div>
                                                                    )}
                                                                </div>

                                                                <div className="w-full px-1 mb-6 md:mb-0">
                                                                    <span className="italic">
                                                                        Xác nhận mật khẩu
                                                                        {' '}
                                                                        <span className="text-red-600">
                                                                            *
                                                                        </span>
                                                                        :
                                                                    </span>
                                                                    <input
                                                                        className="w-full h-8 mt-1 border rounded py-3 px-4 focus:outline-none focus:bg-white"
                                                                        id="confirmPassword"
                                                                        type="password"
                                                                        name="confirmPassword"
                                                                        value={values.confirmPassword}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                    {errors.confirmPassword && touched.confirmPassword && (
                                                                        <div className="" style={{ color: 'red' }}>{errors.confirmPassword}</div>
                                                                    )}
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                                <button
                                                    type="button"
                                                    className="bg-blue-500 hover:bg-blue-600 text-white p-2 focus:outline-none rounded-md w-1/5"
                                                    onClick={handleSubmit}
                                                >
                                                    <span>
                                                        Lưu
                                                    </span>
                                                </button>
                                                <button
                                                    type="button"
                                                    className="mt-3 mr-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                                    onClick={this.toggleModalPasswordChange}
                                                >
                                                    <span>
                                                        Hủy bỏ
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </Transition.Child>
                                </div>
                            </Dialog>
                        </Transition.Root>
                    );
                }}
            </Formik>
        );
    }

    handleCloseAddSchool = () => {
        this.setState({
            addSchoolModal: true,
        });
    }

    toggleShowAccount = () => {
        const { openAccount } = this.state;
        this.setState({
            openAccount: !openAccount,
        });
    }

    handleSignUpInfoExam = (dataSubmit) => {
        const {
            birthOfDate, className, districtId, fullName, gender, provinceId, schoolId, schoolName,
            grade, phone,
        } = dataSubmit;
        const examCode = this.refFormikCodeExam.current.values.code;
        const { toggleSchool } = this.state;
        this.setState({ loading: true });
        const date = moment(birthOfDate).format('DD/MM/YYYY');

        if (examCode) {
            // get school name
            const urlSchool = `${API_URL}/api/school/${districtId}`;
            const dispatchArr = [
                makeRequestGet(urlSchool),
            ];
            Promise.all(dispatchArr)
                .then((res) => {
                    const dataSchool = res[0]?.data.data;
                    // handle data question
                    let schoolN = '';
                    if (toggleSchool) {
                        const schoolFilter = dataSchool.filter((id) => id.id === Number(schoolId));
                        schoolN = schoolFilter[0].name;
                    } else {
                        schoolN = schoolName;
                    }
                    const data = {
                        birthOfDate: date,
                        className,
                        districtId,
                        fullName,
                        gender,
                        provinceId,
                        schoolId,
                        schoolName: schoolN,
                        grade,
                        phone,
                    };
                    const url = `${API_URL}/api/exam-code/${examCode}/create-student`;
                    makeRequestPost(url, null, data).then(() => {
                        this.setState({
                            isModalAddInfomation: true,
                            loading: false,
                        });
                        toast.success('Bổ sung thông tin thành công');
                    }).catch((rs) => {
                        const warning = rs.message;
                        toast.warning(warning);
                        this.setState({
                            isModalAddInfomation: true,
                            loading: false,
                        });
                    });
                })
                .catch(() => {
                    this.setState({
                        isModalAddInfomation: true,
                        loading: false,
                    });
                });
        }
    }

    render() {
        const {
            children, isAuthenticated, fullName, role, schoolName, checkExam,
        } = this.props;
        const {
            loading, isModal, isModalSignUp, provinceData, districtData, schoolData, modalForgotPass, userNameForgotPass,
            isModalQuestion, twoQuestionArr, showPass, isModalSyntax, modal, showPass2, toggleSchool, userName, dataError,
            usernameNotActivated, phoneForgotPass, configDatas, addSchoolModal, openAccount, isModalExamCode,
            isModalAddInfomation, errorMessageExamCode, time, numberClick, seconds, isNotiUserMax,
        } = this.state;
        const registrationTime = configDatas.filter((rtt) => rtt.code === 'THOIGIAN_THITHU');
        const officialExamTime = configDatas.filter((rtt) => rtt.code === 'THOIGIAN_THITHAT');
        const email = configDatas.filter((rtt) => rtt.code === 'EMAIL');
        const phoneNumber = configDatas.filter((rtt) => rtt.code === 'SODIENTHOAI');
        const examDetail = configDatas.filter((rtt) => rtt.code === 'CHITIET_DETHI');
        const totalTime = configDatas.filter((rtt) => rtt.code === 'THOIGIAN_LAMBAI');
        const year = configDatas.filter((rtt) => rtt.code === 'NAM_THI');
        const idYoutube = configDatas.filter((rtt) => rtt.code === 'ID_VIDEO');
        const hotLine = configDatas.filter((rtt) => rtt.code === 'HOTRO_TRUCTUYEN');
        const infoPage = configDatas.filter((rtt) => rtt.code === 'CONTENT_PAGE');
        const linkTimHieuCuocThi = configDatas.filter((rtt) => rtt.code === 'TIM_HIEU_CUOC_THI');
        const linkHuongDan = configDatas.filter((rtt) => rtt.code === 'HUONG_DAN');
        const linkHuongDanDangKy = configDatas.filter((rtt) => rtt.code === 'HUONG_DAN_DANG_KY');
        const linkHuongDanLamBai = configDatas.filter((rtt) => rtt.code === 'HUONG_DAN_LAM_BAI');
        const linkTimHieuKienThuc = configDatas.filter((rtt) => rtt.code === 'TIM_HIEU_KIEN_THUC');

        return (
            <SWRConfig
                value={{
                    fetcher: fetch,
                    onError: (fetchErr) => {
                        console.error(fetchErr);
                    },
                }}
            >
                <Head>
                    <meta charSet="UTF-8" />
                    <title>VNISA</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta property="og:image" content="/images/condenau.png"/>
                </Head>
                <div onMouseLeave={this.handleMouseOver} className={styles.container}>
                    <nav className={`${styles.header}`}>
                        <div className="xl:max-w-7xl mx-auto px-4 sm:px-6 xl:px-8">
                            <div className="flex items-center justify-between h-12">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0 hidden md:block">
                                        <img className="w-20 h-10" alt="logo" src="/svg/logo.svg" />
                                    </div>

                                    <div className="-mr-2 flex md:hidden">
                                        <button
                                            type="button"
                                            className="inline-flex items-center justify-center mt-2 focus:outline-none"
                                        >
                                            <img className="w-8 h-8" alt="menuMb" src="/svg/menuMb.svg" />
                                        </button>
                                    </div>

                                    <div className="hidden md:block pt-2 text-white flex">
                                        {/* <div className="xl:flex xl:flex-wrap lg:flex lg:flex-wrap justify-between relative items-baseline"> */}
                                        <div className="flex relative">
                                            <div className="w-8 h-8 2xl:ml-20 xl:ml-20 lg:ml-20 md:ml-20 bg-red-500 2xl:mt-2 xl:mt-2 lg:mt-2 md:mt-1.5 rounded-3xl absolute">
                                                <img className="w-4 h-6 ml-2 pt-1.5" alt="telephone" src="/svg/telephone.svg" />
                                            </div>
                                            <div className="border-2 2xl:ml-24 xl:ml-24 lg:ml-24 md:ml-24 border-gray-200 rounded-full">
                                                <span className="font-bold text-red-500 text-sm px-8 pt-1">
                                                            Hỗ trợ trực tuyến
                                                    {' '}
                                                    {hotLine[0]?.value}
                                                </span>
                                                <div className="text-xs text-gray-600 text-center pb-1">
                                                            8h-17h, từ thứ 2 đến thứ 6
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                    <div className="hidden md:block">
                                        <a target="_blank" href="http://zalo.me/0981564086" rel="noreferrer">
                                            <img className="w-9 h-9 ml-12 mt-2 cursor-pointer" alt="zalo" src="/svg/zalo.png" />
                                        </a>
                                    </div>
                                    <div className="hidden md:block">
                                        <a target="_blank" href="http://m.me/childsafevietnam" rel="noreferrer">
                                            <img className="w-9 h-9 ml-3 mt-2 cursor-pointer" alt="message" src="/svg/message.png" />
                                        </a>
                                    </div>
                                    <div className="hidden md:block">
                                        <a target="_blank" href="mailto:childsafe@vnisa.org.vn" rel="noreferrer">
                                            <img className="w-9 h-9 ml-3 mt-2 cursor-pointer" alt="email" src="/svg/email.png" />
                                        </a>
                                    </div>
                                    <div className="2xl:hidden xl:hidden lg:hidden md:hidden md:block">
                                        <img className={`${styles.viewLogo} ${'w-20 h-10'}`} alt="logo" src="/svg/logo.svg" />
                                    </div>
                                </div>

                                <div className="-mr-1 flex md:hidden">
                                    <button
                                        type="button"
                                        className="inline-flex items-center justify-center mt-2 focus:outline-none"
                                        aria-controls="mobile-menu"
                                        aria-expanded="false"
                                    >
                                        <img className="w-7 h-7" alt="search" src="/svg/search.svg" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div>
                        <nav className={styles.tabMenus}>
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="flex items-center h-12">
                                    <div className="flex w-full justify-between items-center">
                                        <div className="md:block pt-1">
                                            <div className="flex items-baseline space-x-4">
                                                <div className="flex w-max">
                                                    {menus.map((item, index) => (
                                                        item?.code !== 'tin-tuc'
                                                            ? (
                                                                checkExam === ''
                                                                    ? (
                                                                        <>
                                                                            {item.current === false
                                                                                ? (
                                                                                    <Link href={item.link} key={index}>
                                                                                        <img className="w-6 h-6 cursor-pointer mr-4" alt="home" src="/svg/home.svg" />
                                                                                    </Link>
                                                                                )
                                                                                : item.link !== '/thi'
                                                                                    ? (
                                                                                        <>
                                                                                            {item.link === ''
                                                                                                ? (
                                                                                                    <span
                                                                                                        className={`${styles.textMenu} ${'text-white font-semibold 2xl:text-base xl:text-sm lg:text-sm md:text-sm 2xl:p-6 xl:p-6 lg:p-6 md:p-6 2xl:py-1 xl:py-1 lg:py-1 md:py-1 rounded-md'}`}
                                                                                                        aria-current={item.current ? 'page' : undefined}
                                                                                                        onClick={this.toggleModalExamCode}
                                                                                                    >
                                                                                                        {item.name} 
                                                                                                    </span>
                                                                                                )
                                                                                                : (
                                                                                                    <Link href={item.link} key={index}>
                                                                                                        <span
                                                                                                            className={`${styles.textMenu} ${'text-white font-semibold 2xl:text-base xl:text-sm lg:text-sm md:text-sm 2xl:p-6 xl:p-6 lg:p-6 md:p-6 2xl:py-1 xl:py-1 lg:py-1 md:py-1 rounded-md'}`}
                                                                                                            aria-current={item.current ? 'page' : undefined}
                                                                                                        >
                                                                                                            {item.name} 
                                                                                                        </span>
                                                                                                    </Link>
                                                                                                )}
                                                                                        </>
                                                                                    )
                                                                                    : (isAuthenticated && role === 'ROLE_STUDENT')
                                                                                        ? (
                                                                                            <Link href={item.link} key={index}>
                                                                                                <span
                                                                                                    className={`${styles.textMenu} ${'text-white font-semibold 2xl:text-base xl:text-sm lg:text-sm md:text-sm 2xl:p-6 xl:p-6 lg:p-6 md:p-6 2xl:py-1 xl:py-1 lg:py-1 md:py-1 rounded-md'}`}
                                                                                                    aria-current={item.current ? 'page' : undefined}
                                                                                                >
                                                                                                    {item.name} 
                                                                                                </span>
                                                                                            </Link>
                                                                                        )
                                                                                        : (
                                                                                            <span
                                                                                                className={`${styles.textMenu} ${'text-white font-semibold 2xl:text-base xl:text-sm lg:text-sm md:text-sm 2xl:p-6 xl:p-6 lg:p-6 md:p-6 2xl:py-1 xl:py-1 lg:py-1 md:py-1 rounded-md'}`}
                                                                                                aria-current={item.current ? 'page' : undefined}
                                                                                                onClick={this.toggleModal}
                                                                                            >
                                                                                                {item.name} 
                                                                                            </span>
                                                                                        )}
                                                                        </>
                                                                    )
                                                                    : item.current === false
                                                                        ? <img className="w-6 h-6 cursor-pointer mr-4" alt="home" src="/svg/home.svg" />
                                                                        : (
                                                                            <span
                                                                                onClick={this.toggleCanMove}
                                                                                key={index}
                                                                                className={`${styles.textMenu} ${'text-white font-semibold 2xl:text-base xl:text-sm lg:text-sm md:text-sm 2xl:p-6 xl:p-6 lg:p-6 md:p-6 2xl:py-1 xl:py-1 lg:py-1 md:py-1 rounded-md'}`}
                                                                                aria-current={item.current ? 'page' : undefined}
                                                                            >
                                                                                {item.name}
                                                                            </span>
                                                                        )
                                                            )
                                                            : (
                                                                <a target="_blank" key={index} href={linkTimHieuCuocThi[0]?.value} rel="noreferrer" className="2xl:mt-1 xl:mt-px">
                                                                    <span
                                                                        className={`${styles.textMenu} ${styles.textMenuMobi} ${'text-white font-semibold 2xl:text-base xl:text-sm lg:text-sm md:text-sm 2xl:p-6 xl:p-6 lg:p-6 md:p-6 2xl:py-1 xl:py-1 lg:py-1 md:py-1 rounded-md'}`}
                                                                        aria-current={item.current ? 'page' : undefined}
                                                                    >
                                                                        {item.name}
                                                                    </span>
                                                                </a>
                                                            )
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${styles.textInfo} ${'hidden md:block xl:ml-40 lg:ml-40 lg:w-auto relative lg:relative xl:block xl:flex-grow xl:flex xl:items-center xl:w-auto'}`}>
                                            <div className="text-sm xl:flex-grow lg:flex-grow" />
                                            {(isAuthenticated && role === 'ROLE_STUDENT')
                                                ? (
                                                    <>
                                                        <div className={`${'flex'}`}>
                                                            <div className="">
                                                                <div className="float-right">
                                                                    <span className="2xl:text-base xl:text-base lg:text-base md:text-sm font-semibold text-white right-0">{fullName}</span>
                                                                </div>
                                                                <br />
                                                                <div className="float-right">
                                                                    <span className="text-xs text-white right-0">{schoolName}</span>
                                                                </div>
                                                            </div>
                                                            <img id="userpopup" className="w-9 h-9 mt-1.5 ml-4 cursor-pointer" alt="profile" src="/svg/profile.svg" />
                                                        </div>

                                                        <UncontrolledPopover trigger="legacy" placement="top" target="userpopup" className={styles.userPopUp}>
                                                            <div className="bg-gray-100 text-center mt-0.5 rounded-xl">
                                                                <div className="w-48">
                                                                    <div
                                                                        className="pb-2 pt-2 cursor-pointer font-semibold hover:bg-gray-400 hover: rounded-t-xl"
                                                                        onClick={checkExam === '' ? this.toggleUserInfo : this.toggleCanMove}
                                                                    >
                                                                                Thông tin cá nhân
                                                                    </div>
                                                                    <div
                                                                        className="pb-2 pt-2 cursor-pointer border-t hover:bg-gray-400 text-danger"
                                                                        onClick={this.toggleModalPasswordChange}
                                                                    >
                                                                                Đổi mật khẩu
                                                                    </div>
                                                                    <div className="pb-2 pt-2 border-t hover:bg-gray-400 hover: rounded-b-xl">
                                                                        <span className="text-danger cursor-pointer" onClick={checkExam === '' ? this.handleClickSignOut.bind(this) : this.toggleCanMove}>Đăng Xuất</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </UncontrolledPopover>
                                                    </>
                                                )
                                                : (
                                                    <>
                                                        <div className="text-center">
                                                            <div onClick={this.toggleModal}>
                                                                <span className={`${styles.buttonsignOut} ${'inline-block text-sm w-auto xl:mr-8 xl:px-0 xl:py-2 xl:mt-0 2xl:mr-8 2xl:px-4 2xl:py-2 2xl:mt-0 rounded-2xl text-white leading-none cursor-pointer hover:text-white hover:bg-blue-800'}`}>
                                                                            Đăng nhập
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="text-center">
                                                            <div onClick={this.toggleModalSignUp}>
                                                                <span className={`${styles.buttonsignOut} ${'inline-block text-sm xl:mr-8 xl:px-2 xl:py-2 xl:mt-0 2xl:mr-8 2xl:px-4 2xl:py-2 2xl:mt-0 rounded-2xl text-white leading-none cursor-pointer hover:text-white hover:bg-blue-800'}`}>
                                                                            Đăng ký
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                        </div>
                                    </div>
                                    <div className="-mr-2 flex md:hidden">
                                        <button
                                            id="ScheduleUpdateButton"
                                            aria-controls="mobile-menu"
                                            aria-expanded="false"
                                            onClick={this.toggleShowAccount}
                                            className="border item-center justify-center border-bg-white w-24 rounded-full py-1 text-gray-400 hover:text-white focus:outline-none"
                                        >
                                            <span className={`${'flex inline-block text-xs text-white ml-3'}`}>
                                                        Tài khoản
                                                {openAccount === false
                                                    ? <img className="w-3 h-3 ml-1 mt-0.5" alt="arrowDown" src="/svg/arrowDown.svg" />
                                                    : <img className="w-3 h-3 ml-1 mt-0.5" alt="arrowUp" src="/svg/arrowUp.svg" />}
                                            </span>
                                        </button>
                                        <UncontrolledPopover trigger="legacy" placement="top" target="ScheduleUpdateButton" className={styles.userPopUp}>
                                            <div className={`${styles.bgUserPopup} ${'text-center mt-2 rounded-xl'}`}>
                                                <div className="w-28">
                                                    {
                                                        isAuthenticated
                                                            ? (
                                                                <>
                                                                    <div
                                                                        className={styles.username}
                                                                        onClick={checkExam === '' ? this.toggleUserInfo : this.toggleCanMove}
                                                                    >
                                                                        {fullName}
                                                                    </div>

                                                                    <div
                                                                        className="text-white pb-2 pt-2 cursor-pointer border-t hover:bg-gray-400 text-sm"
                                                                        onClick={this.toggleModalPasswordChange}
                                                                    >
                                                                                Đổi mật khẩu
                                                                    </div>
                                                                    <div className="pb-2 border-t hover:bg-gray-400 hover: rounded-b-xl">
                                                                        <span className="text-white text-sm cursor-pointer" onClick={checkExam === '' ? this.handleClickSignOut.bind(this) : this.toggleCanMove}>Đăng Xuất</span>
                                                                    </div>
                                                                </>
                                                            )
                                                            : (
                                                                <>
                                                                    <div className="pb-1 hover:bg-gray-400 hover: rounded-t-xl">
                                                                        <span className="text-white text-danger cursor-pointer" onClick={this.toggleModal}>Đăng nhập</span>
                                                                    </div>
                                                                    <div className="pb-1 hover:bg-gray-400 hover: rounded-b-xl border-t border-bg-white">
                                                                        <span className="text-white text-danger cursor-pointer" onClick={this.toggleModalSignUp}>Đăng ký</span>
                                                                    </div>
                                                                </>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </UncontrolledPopover>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <main className={styles.main}>
                        <div className={styles.mainContent} ref={this.wrapperRef}>
                            {children
                                        || (
                                            <>
                                                <div className="2xl:flex xl:flex lg:flex md:flex sm:flex xl:w-7/12 lg:w-8/12 w-full m-auto mt-8 justify-between 2xl:w-7/12">
                                                    <div className={`${styles.gioithieu} ${'2xl:w-6/12 xl:w-6/12 lg:w-6/12 md:w-6/12 sm:w-6/12 sm:text-center 2xl:pl-4 xl:pl-4 lg:pl-4 md:pl-4 sm:pl-4'}`}>
                                                        <div className="text-2xl">Cuộc thi online</div>
                                                        <div style={{ color: '#3a80c2' }} className={`${styles.titleWeb} ${'text-3xl font-bold mt-1'}`}>HỌC SINH VỚI</div>
                                                        <div style={{ color: '#3a80c2' }} className={`${styles.titleWeb} ${'text-3xl font-bold mt-1'}`}>
                                                            AN TOÀN THÔNG TIN
                                                            {' '}
                                                            {year[0]?.value}
                                                        </div>
                                                        <div className="mt-2">
                                                            <i className={`${styles.text4} ${'text-l'}`}>{infoPage[0]?.value}</i>
                                                        </div>
                                                        <div className={`${styles.text4} ${'mt-5'}`}>
                                                            Thời gian thi thử và ôn tập:
                                                            {' '}
                                                            <span style={{ color: '#3a80c2' }} className="font-semibold">{registrationTime[0]?.value}</span>
                                                        </div>
                                                        <div className={`${styles.text4} ${'mb-8'}`}>
                                                            Thời gian thi chính thức:
                                                            {' '}
                                                            <span style={{ color: '#3a80c2' }} className="font-semibold">{officialExamTime[0]?.value}</span>
                                                        </div>
                                                        <div className={`${styles.gioithieu} ${'flex 2xl:justify-left xl:justify-left lg:justify-left md:justify-left sm:justify-left 2xl:flex-wrap xl:flex-wrap lg:flex-wrap md:flex-wrap sm:flex-wrap'}`}>
                                                            <div className="mr-2">
                                                                <button onClick={this.gotoExam} style={{ background: '#3a80c2' }} className="bg-green-500 text-white py-2 px-5">VÀO THI</button>
                                                            </div>
                                                            <div>
                                                                <a target="_blank" href={linkHuongDan[0]?.value} rel="noreferrer">
                                                                    <button style={{ background: '#3a80c2' }} className="text-white py-2 px-5">HƯỚNG DẪN</button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <img style={{ maxWidth: '327px' }} alt="conde" src="/images/condenau.png" className={`${styles.anhde} ${'2xl:w-6/12 xl:w-6/12 lg:w-6/12 sm:w-6/12 md:w-5/12 2xl:-mt-8 xl:-mt-8 lg:-mt-8 md:-mt-8 sm:-mt-8 '}`} />
                                                </div>
                                                <div className={`${styles.backgroundOverview} pb-20 2xl:flex xl:flex lg:flex md:flex sm:flex`}>
                                                    <div className="2xl:w-7/12 xl:w-7/12 lg:w-7/12 md:w-7/12 sm:w-7/12 mt-14 px-6">
                                                        <div style={{ color: '#3a80c2' }} className="mb-5 text-center text-2xl font-bold">
                                                            TỔNG QUAN
                                                        </div>
                                                        <div className="text-left mx-5">
                                                            Cuộc thi nhằm tuyên truyền, cung cấp kiến thức, kỹ năng sử dụng mạng internet an toàn cho học sinh và phụ huynh học sinh trên cả nước. Tạo ra sân chơi lành
                                                            mạnh, bổ ích, phát huy khả năng tư duy của học sinh, giúp các em nhận diện và phòng, tránh nguy cơ mất an toàn trên môi trường mạng.
                                                        </div>
                                                        <div className="text-left mb-3 mt-1 mx-5">
                                                            Cuộc thi là một hoạt động hưởng ứng Chương trình "Bảo vệ và hỗ trợ trẻ em tương tác lành mạnh, sáng tạo trên môi trường mạng" được Thủ tướng Chính
                                                            phủ phê duyệt ngày 01/06/2021.<a target="_blank" href="https://childsafe.vn" ref="noreferrer" className="text-blue-400" rel="noreferrer">(Xem thêm)</a>
                                                        </div>
                                                        <div className={`${styles.viewButtonHd} ${'2xl:flex xl:flex lg:flex md:flex sm:flex 2xl:justify-evenly xl:justify-evenly lg:justify-evenly md:justify-evenly sm:justify-evenly 2xl:flex-wrap xl:flex-wrap lg:flex-wrap md:flex-wrap sm:flex-wrap mb-3'}`}>
                                                            <div>
                                                                <a target="_blank" href={linkHuongDanDangKy[0]?.value} rel="noreferrer">
                                                                    <button className="bg-green-500 text-white py-2 px-5 rounded">Hướng dẫn đăng ký</button>
                                                                </a>
                                                            </div>
                                                            <div className={styles.hd}>
                                                                <a target="_blank" href={linkHuongDanLamBai[0]?.value} rel="noreferrer">
                                                                    <button style={{ background: '#3a80c2' }} className="text-white py-2 px-5 rounded">Hướng dẫn làm bài thi</button>
                                                                </a>
                                                            </div>
                                                            <div className={styles.hd}>
                                                                <a target="_blank" href={linkTimHieuKienThuc[0]?.value} rel="noreferrer">
                                                                    <button className="bg-red-400 text-white py-2 px-5 rounded">Tìm hiểu kiến thức</button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="text-left mt-1 mx-5">
                                                            Đề thi gồm
                                                            {' '}
                                                            {examDetail[0]?.value}
                                                            . Thời gian làm bài
                                                            {' '}
                                                            {totalTime[0]?.value}
                                                            .
                                                            {' '}
                                                        </div>
                                                        <div className="text-left font-semibold mt-1 mx-5">
                                                            <div className = "pt-3">Mỗi thí sinh chỉ dự thi chính thức 1 lần duy nhất (hạn chế thi trong khoảng 19h00 - 22h00 để hệ thống không bị quá tải).</div>
                                                        </div>
                                                        <div className="text-left align-middle font-semibold mt-1 flex mx-5">
                                                            <div className = "pt-3">Khuyến nghị thí sinh sử dụng trình duyệt Cốc Cốc để dự thi</div><a target="_blank" href="https://app.coccoc.com/thihsattt" rel="noreferrer">
                                                                <img style={{
                                                                    height:"3rem"
                                                                }} className=" cursor-pointer" src="/svg/taicoccoc.png" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="2xl:w-5/12 xl:w-5/12 lg:w-5/12 md:w-5/12 sm:w-5/12 mt-14">
                                                        <div className="text-blue-500 text-2xl text-center mb-6">VIDEO GIỚI THIỆU CUỘC THI</div>
                                                        <YouTube className="w-11/12 m-auto h-72" videoId={idYoutube[0]?.value} />
                                                    </div>
                                                </div>
                                                <div className="w-11/12 m-auto mt-8 mb-10">
                                                    <div className="border border-gray-300 w-full py-3 mb-3">
                                                        <div className="underline text-center mb-2 text-blue-500">Đơn vị bảo trợ</div>
                                                        <div className="text-center 2xl:flex xl:flex lg:flex 2xl:justify-between xl:justify-between lg:justify-between md:justify-between sm:justify-between 2xl:flex-wrap xl:flex-wrap lg:flex-wrap md:flex-wrap sm:flex-wrap px-10">
                                                            <div className={`${styles.donvibaotro} ${'text-blue-500'}`}>BỘ THÔNG TIN VÀ TRUYỀN THÔNG</div>
                                                            <div className={`${styles.donvibaotro} ${'text-blue-500'}`}>BỘ GIÁO DỤC VÀ ĐÀO TẠO</div>
                                                            <div className={`${styles.donvibaotro} ${'text-blue-500'}`}>BỘ LAO ĐỘNG THƯƠNG BINH VÀ XÃ HỘI</div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full py-3 2xl:flex xl:flex lg:flex md:flex sm:flex justify-between">
                                                        <div className="border border-gray-300 text-blue-500 2xl:w-1/3 xl:w-1/3 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full pt-4">
                                                            <div className="underline text-center mb-2 text-blue-500">Đơn vị chủ trì</div>
                                                            <div className="flex justify-between px-4">
                                                                <div className="w-6/12">
                                                                    <img className={`${styles.imgVinsa} ${'cursor-pointer w-20 h-10'}`} alt="logo" src="/svg/logo.svg" />
                                                                </div>
                                                                <div className={`${styles.donvichutri} ${'text-blue-500 text-center w-6/12'}`}>
                                                                    HIỆP HỘI AN TOÀN THÔNG TIN VIỆT NAM
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`${styles.mrt} ${'border border-gray-300 2xl:w-2/3 xl:w-2/3 lg:w-2/3 md:w-2/3 sm:w-2/3 w-full pt-4 2xl:ml-4 xl:ml-4 lg:ml-4 md:ml-4 sm:ml-4'}`}>
                                                            <div className="underline text-center mb-2 text-blue-500">Đơn vị đồng tổ chức</div>
                                                            <div style={{ color: '#3a80c2' }} className="flex justify-between lex-wrap px-4">
                                                                <div className="text-center w-1/3 pr-4">
                                                                    <div className={`${styles.donvitochuc} ${'font-medium'}`}>CỤC AN TOÀN THÔNG TIN</div>
                                                                    <div className={`${styles.textbo} ${'text-blue-500 text-center'}`}>
                                                                        (BỘ TT&TT)
                                                                    </div>
                                                                </div>
                                                                <div className="text-center w-1/3">
                                                                    <div className={`${styles.donvitochuc} ${'font-medium'}`}>VỤ GIÁO DỤC CHÍNH TRỊ VÀ CÔNG TÁC HSSV</div>
                                                                    <div className={`${styles.textbo} ${'text-blue-500 text-center'}`}>
                                                                        (BỘ GDĐT)
                                                                    </div>
                                                                </div>
                                                                <div className="text-center w-1/3 pl-4">
                                                                    <div className={`${styles.donvitochuc} ${'font-medium'}`}>CỤC TRẺ EM</div>
                                                                    <div className={`${styles.textbo} ${'text-blue-500 text-center'}`}>
                                                                        (BỘ LĐ-TBXH)
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full py-3 2xl:flex xl:flex lg:flex md:flex sm:flex 2xl:justify-between xl:justify-between lg:justify-between md:justify-between sm:justify-between">
                                                        <div className="border border-gray-300 w-full pt-4">
                                                            <div className="underline text-center mb-2 text-blue-500">
                                                                Đơn vị phối hợp chính
                                                            </div>
                                                            <div className="pl-4">
                                                                <div className="w-full items-center flex justify-center">
                                                                    <div className="w-3/12">
                                                                        <a href="https://www.bkav.com.vn/" target="_blank" rel="noreferrer">
                                                                            <img
                                                                                className="cursor-pointer"
                                                                                alt="BKAV"
                                                                                src="/svg/BKAV.svg"
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                    <div className="w-3/12">
                                                                        <a href="https://www.tiktok.com/vi-VN/" target="_blank" rel="noreferrer">
                                                                            <img
                                                                                className="cursor-pointer"
                                                                                alt="tiktok"
                                                                                src="/svg/tiktok.svg"
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                    <div className="w-3/12">
                                                                        <a href="https://childfund.org.vn/vi/" target="_blank" rel="noreferrer">
                                                                            <img
                                                                                className="cursor-pointer"
                                                                                alt="childfund"
                                                                                src="/svg/childfund.svg"
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                    <div className="w-3/12">
                                                                        <a href="https://plan-international.org/" target="_blank" rel="noreferrer">
                                                                            <img
                                                                                className="cursor-pointer"
                                                                                alt="plan-international"
                                                                                src="/svg/plan-international.png"
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                    <div className="w-3/12 mt-3">
                                                                        <a href="https://wvi.org.vn/" target="_blank" rel="noreferrer">
                                                                            <img
                                                                                className="cursor-pointer"
                                                                                alt="worldVision"
                                                                                src="/svg/worldVision.svg"
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                    <div className="w-3/12">
                                                                        <a href="https://msdvietnam.org/" target="_blank" rel="noreferrer">
                                                                            <img
                                                                                className="cursor-pointer"
                                                                                alt="msd"
                                                                                src="/svg/msd.svg"
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                    <div className="w-3/12">
                                                                        <a href="https://www.cmc.com.vn/" target="_blank" rel="noreferrer">
                                                                            <img
                                                                                className="cursor-pointer"
                                                                                alt="cmc"
                                                                                src="/svg/cmc.jpg"
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                    <div className="w-3/12">
                                                                        <a href="https://coccoc.vn/" target="_blank" rel="noreferrer">
                                                                            <img
                                                                                className="cursor-pointer"
                                                                                alt="coccoc"
                                                                                src="/svg/coccoc.jpg"
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                        </div>
                    </main>

                    <div className={`${styles.footer} ${'flex w-full bottom-0 py-4 2xl:py-4 xl:py-4 lg:py-4 md:py-4 sm:py-4'}`}>
                        <div className={`${styles.viewFooter} ${'lg:w-10/12 w-11/12 2xl:flex xl:flex lg:flex md:flex sm:flex justify-between m-auto'}`}>
                            <div className={`${styles.text1} ${'2xl:w-4/12 xl:w-4/12 lg:w-4/12 md:w-4/12 sm:w-4/12'}`}>
                                <div>Bản quyền thuộc Hiệp hội An toàn thông tin Việt Nam</div>
                                <div>
                                            Email:
                                    {' '}
                                    <a className="text-blue-500" href={`mailto:${email[0]?.value}`}>{email[0]?.value}</a>
                                </div>
                                <div>
                                            Hỗ trợ trực tuyến:
                                    {' '}
                                    <a className="text-blue-500" href={`tel:${phoneNumber[0]?.value}`}>{phoneNumber[0]?.value}</a>
                                </div>
                            </div>
                            <div className={`${styles.text2} ${'2xl:w-4/12 xl:w-4/12 lg:w-4/12 md:w-4/12 sm:w-4/12'}`}>
                                <div className="2xl:text-center xl:text-center lg:text-center md:text-center sm:text-center">
                                            Bảo trợ truyền thông
                                </div>
                                <div className="flex justify-center">
                                    <div>
                                        <a href="http://antoanthongtin.vn/" target="_blank" rel="noreferrer">
                                            <img
                                                className="cursor-pointer"
                                                alt="attt"
                                                src="/svg/attt.svg"
                                            />
                                        </a>
                                    </div>
                                    <div>
                                        <a href="http://ictvietnam.vn/" target="_blank" rel="noreferrer">
                                            <img
                                                className="cursor-pointer"
                                                alt="tttt"
                                                src="/svg/tttt.svg"
                                            />
                                        </a>
                                    </div>
                                    <div>
                                        <a href="http://chili.vn/" target="_blank" rel="noreferrer">
                                            <img
                                                className="cursor-pointer"
                                                alt="chili"
                                                src="/svg/chili.svg"
                                            />
                                        </a>
                                    </div>
                                    <div>
                                        <a href="https://netnam.com/" target="_blank" rel="noreferrer">
                                            <img
                                                height={30}
                                                width={67}
                                                className="cursor-pointer"
                                                alt="netnam"
                                                src="/svg/netnam.png"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.text3} ${'2xl:w-4/12 xl:w-4/12 lg:w-4/12 md:w-4/12 sm:w-4/12'}`}>
                                <div className="2xl:w-7/12 xl:w-7/12 lg:w-7/12 md:w-7/12 sm:w-7/12 m-auto">
                                            Fanpage cuộc thi
                                </div>
                                <div>
                                    <a href="https://www.facebook.com/childsafevietnam" target="_blank" rel="noreferrer">
                                        <img
                                            className={`${styles.fanpage} ${'cursor-pointer 2xl:w-7/12 xl:w-7/12 lg:w-7/12 md:w-7/12 sm:w-7/12 m-auto'}`}
                                            alt="fanpage"
                                            src="/svg/fanpage.svg"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div hidden={isModal} className=" fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="inline-block w-11/12 sm:w-5/6 lg:w-5/6 xl:w-3/5 2xl:w-1/4 max-w-2xl mx-auto rounded-2xl bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                            <div
                                className={`${styles.headerSignin} ${'flex flex-row text-white justify-between p-3 border-b rounded-tl-lg rounded-tr-lg'}`}
                            >
                                <p className={`${styles.textLogin} ${'font-bold text-white'}`}>ĐĂNG NHẬP</p>
                                <svg xmlns="http://www.w3.org/2000/svg" onClick={this.toggleModal} className="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={this.handleSubmitSignin}
                                validationSchema={validationSchema}
                                innerRef={this.refFormik}
                            >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = props;
                                    return (
                                        <Form onSubmit={handleSubmit} className="p-5 bg-white flex justify-center flex-col shadow-md">
                                            <div className="flex flex-col px-1 py-5 rounded-br-2xl rounded-bl-2xl">
                                                {dataError === true ? (
                                                    <div>
                                                                Bạn hãy nhắn tin theo cú pháp
                                                        {' '}
                                                        <span className="text-green-500">
                                                                    “HSATTT
                                                            {' '}
                                                            {usernameNotActivated}
                                                                    ”
                                                        </span>
                                                        {' '}
                                                                gửi đến
                                                        {' '}
                                                        <span className="text-green-500">8100</span>
                                                        {' '}
                                                                để kích hoạt tài khoản
                                                        {' '}
                                                        <span className="text-green-500">{usernameNotActivated}</span>
                                                                .
                                                        {' '}
                                                                Trân trọng cảm ơn
                                                    </div>
                                                ) : ''}
                                                <input
                                                    type="username"
                                                    name="username"
                                                    value={values.username}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`${styles.namePlaceholder} ${'mt-5 w-full p-3 h-10 focus:border-blue-600 rounded-md border-2 outline-none'}`}
                                                    placeholder="Tên đăng nhập *"
                                                    disabled={loading}
                                                />
                                                {errors.username && touched.username && (
                                                    <div style={{ color: 'red' }}>{errors.username}</div>
                                                )}
                                                <div>
                                                    <div>
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={`${styles.namePlaceholder} ${'mt-5 w-full p-3 h-10 focus:border-blue-600 rounded-md border-2 outline-none'}`}
                                                            placeholder="Mật khẩu *"
                                                            disabled={loading}
                                                        />
                                                    </div>
                                                    {errors.password && touched.password && (
                                                        <div style={{ color: 'red' }}>{errors.password}</div>
                                                    )}
                                                </div>
                                                <div className="mt-5 w-full">
                                                    <ReCAPTCHA
                                                        ref={(el) => { this.recaptcha = el; }}
                                                        sitekey="6Lezp-QaAAAAAF3DeabNtFe9HTqYrPyV9GVGtpsw"
                                                        onChange={this.handleCaptchaResponseChange}
                                                        size="normal"
                                                    />
                                                </div>
                                                <button
                                                    disabled={loading}
                                                    className={`${styles.button} ${'mt-8 font-semibold text-white p-1.5 rounded-md w-full'}`}
                                                    onClick={handleSubmit}
                                                    type="submit"
                                                >
                                                    <span>Đăng nhập</span>
                                                </button>

                                                <div className="flex justify-center">
                                                    <span className="mt-4">
                                                        <i>Quên</i>
                                                        {' '}
                                                        <span onClick={this.toggleModalForgotPass} className={styles.textSignUp}>
                                                            <i className={`${'underline'}`}>Mật khẩu</i>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="flex justify-center">
                                                    <span className="mt-3">
                                                        <i>Chưa có tài khoản đăng kí</i>
                                                        {' '}
                                                        <span onClick={this.toggleModalSignUp}>
                                                            <i className={`${styles.textSignUp} ${'underline'}`}>Tại đây</i>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
                <div hidden={isModalSignUp} className=" fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="inline-block w-11/12 sm:w-5/6 lg:w-7/12 mx-auto rounded-2xl bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                            <div
                                className={`${styles.headerSignin} ${'flex flex-row text-white justify-between p-3 border-b rounded-tl-lg rounded-tr-lg '}`}
                            >
                                <div className={`${'font-bold text-white text-center w-full'}`}>ĐĂNG KÝ TÀI KHOẢN</div>
                                <svg xmlns="http://www.w3.org/2000/svg" onClick={this.toggleModalSignUp} className="h-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <Formik
                                initialValues={initialValuesSignUp}
                                onSubmit={this.handleSignUp}
                                validationSchema={toggleSchool ? validationSchemaSignUp : validationSchemaSignUp2}
                                innerRef={this.refFormikSignUp}
                            >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = props;
                                    return (
                                        <Form onSubmit={handleSubmit} className="p-5 bg-white flex justify-center flex-col shadow-md">
                                            <div className="lg:flex lg:flex-wrap lg:justify-between">
                                                <div className="lg:w-6/12 pr-3">
                                                    <div className={`${styles.title} font-bold mt-2`}>Thông tin học sinh:</div>
                                                    <input
                                                        type="text"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.fullName}
                                                        name="fullName"
                                                        id="fullName"
                                                        className="border-2 pl-3 pr-3 p-2 w-full mt-5 rounded-md"
                                                        placeholder="Họ và Tên *"
                                                    />
                                                    {errors.fullName && touched.fullName && (
                                                        <div className="" style={{ color: 'red' }}>{errors.fullName}</div>
                                                    )}
                                                    <div className="flex flex-wrap w-full">
                                                        <div className="w-full md:w-6/12 md:pr-1">
                                                            <input
                                                                type="text"
                                                                onFocus={this.onFocus}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.birthOfDate}
                                                                name="birthOfDate"
                                                                id="birthOfDate"
                                                                className="border-2 pl-3 pr-3 p-2 w-full mt-5 rounded-md"
                                                                placeholder="Ngày sinh (dd/mm/yyyy)"
                                                            />
                                                            {errors.birthOfDate && touched.birthOfDate && (
                                                                <div className="" style={{ color: 'red' }}>{errors.birthOfDate}</div>
                                                            )}
                                                        </div>
                                                        <div className="w-full md:w-6/12 md:pl-1">
                                                            <input
                                                                type="phone"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.phone}
                                                                name="phone"
                                                                className="border-2 pl-3 pr-3 p-2 w-full mt-5 rounded-md"
                                                                placeholder="Số điện thoại liên hệ *"
                                                            />
                                                            {errors.phone && touched.phone && (
                                                                <div className="" style={{ color: 'red' }}>{errors.phone}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.username}
                                                        name="username"
                                                        className="border-2 pl-3 pr-3 p-2 w-full mt-5 rounded-md"
                                                        placeholder="Tên đăng nhập *"
                                                    />
                                                    {errors.username && touched.username && (
                                                        <div className="" style={{ color: 'red' }}>{errors.username}</div>
                                                    )}
                                                    <div className="relative">
                                                        <input
                                                            type={showPass === true ? 'password' : 'text'}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.password}
                                                            name="password"
                                                            className="border-2 pl-3 pr-12 p-2 w-full mt-5 rounded-md"
                                                            placeholder="Mật khẩu *"
                                                        />
                                                        {errors.password && touched.password && (
                                                            <div className="" style={{ color: 'red' }}>{errors.password}</div>
                                                        )}
                                                        {showPass
                                                            ? <img onClick={this.handleShowPass.bind(this, 'show')} className="w-6 h-6 absolute cursor-pointer right-4 top-8 opacity-50" alt="tableOfContent" src="/svg/eye.svg" />
                                                            : <img onClick={this.handleShowPass.bind(this, 'hide')} className="w-6 h-6 absolute cursor-pointer right-4 top-8 opacity-50" alt="tableOfContent" src="/svg/eyeInvisible.svg" />}
                                                    </div>
                                                    <div className="relative">
                                                        <input
                                                            type={showPass2 === true ? 'password' : 'text'}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.passwordAgain}
                                                            name="passwordAgain"
                                                            className="border-2 pl-3 pr-12 p-2 w-full mt-5 rounded-md"
                                                            placeholder="Nhập lại mật khẩu *"
                                                        />
                                                        {errors.passwordAgain && touched.passwordAgain && (
                                                            <div className="" style={{ color: 'red' }}>{errors.passwordAgain}</div>
                                                        )}
                                                        {showPass2
                                                            ? <img onClick={this.handleShowPass.bind(this, 'show2')} className="w-6 h-6 absolute cursor-pointer right-4 top-8 opacity-50" alt="tableOfContent" src="/svg/eye.svg" />
                                                            : <img onClick={this.handleShowPass.bind(this, 'hide2')} className="w-6 h-6 absolute cursor-pointer right-4 top-8 opacity-50" alt="tableOfContent" src="/svg/eyeInvisible.svg" />}
                                                    </div>
                                                    <div className="flex flex-wrap lg:mt-4 mt-2">
                                                        {/* <div className="mt-5 w-full"> */}
                                                        <ReCAPTCHA
                                                            ref={this.recaptcha2}
                                                            sitekey="6Lezp-QaAAAAAF3DeabNtFe9HTqYrPyV9GVGtpsw"
                                                            onChange={this.handleCaptchaResponseChange}
                                                            size="normal"
                                                        />
                                                        {/* </div> */}
                                                    </div>
                                                    {/* {errors.securityCode && touched.securityCode && (
                                                            <div className="" style={{ color: 'red' }}>{errors.securityCode}</div>
                                                        )} */}
                                                </div>
                                                <div className="lg:w-6/12 pl-3">
                                                    <div className={`${styles.title} font-bold mt-2`}>Thông tin trường:</div>
                                                    <select onChange={this.handleChooseProvince} value={values.provinceId} name="provinceId" className="pl-3 pr-3 h-11 mt-5 border-2 p-1 w-full rounded-md">
                                                        <option value="">Tỉnh/Thành phố *</option>
                                                        {provinceData.map((pv, index) => (
                                                            <option key={index} value={pv.id}>{pv.name}</option>
                                                        ))}
                                                    </select>
                                                    {errors.provinceId && touched.provinceId && (
                                                        <div className="" style={{ color: 'red' }}>{errors.provinceId}</div>
                                                    )}
                                                    <select onChange={this.handleChooseDistrict} value={values.districtId} name="districtId" className="pl-3 pr-3 h-11 mt-5 border-2 p-1 w-full rounded-md">
                                                        <option value="">Quận/Huyện *</option>
                                                        {districtData.map((dt, index) => (
                                                            <option key={index} value={dt.id}>{dt.name}</option>
                                                        ))}
                                                    </select>
                                                    {errors.districtId && touched.districtId && (
                                                        <div className="" style={{ color: 'red' }}>{errors.districtId}</div>
                                                    )}
                                                    <div className="flex flex-wrap mt-8 lg-w-full ml-1">
                                                        <div className="lg:w-5/12 w-4/12">
                                                                        Giới tính:
                                                        </div>
                                                        <label className="flex lg:w-4/12 w-3/12" htmlFor="male">
                                                            <input
                                                                type="radio"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value="1"
                                                                name="gender"
                                                                id="male"
                                                                className="mt-2 mr-1"
                                                                defaultChecked={values.gender === 1}
                                                            />
                                                                        Nam
                                                        </label>
                                                        <label className="flex" htmlFor="female">
                                                            <input
                                                                type="radio"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value="2"
                                                                name="gender"
                                                                id="female"
                                                                className="mt-2 mr-1"
                                                                defaultChecked={values.gender === 2}
                                                            />
                                                                        Nữ
                                                        </label>
                                                    </div>
                                                    <div className="w-full lg:mt-8 mt-5 flex flex-wrap justify-between">
                                                        <select onChange={this.handleChooseSchool} value={values.schoolId} name="schoolId" className="pl-3 pr-3 h-11 border-2 p-1 rounded-md w-full xl:w-8/12" disabled={!values.districtId}>
                                                            <option value="">Trường *</option>
                                                            {schoolData.map((sc, index) => (
                                                                <option key={index} value={sc.id}>{sc.name}</option>
                                                            ))}
                                                        </select>
                                                        {values.schoolId === '' && values.districtId !== ''
                                                            ? (
                                                                <button type="button" onClick={this.toggleSchool} className="hidden xl:block w-4/12 border border-green-400 text-green-400 rounded-md hover:bg-green-400 hover:text-white">
                                                                                Thêm trường
                                                                </button>
                                                            )
                                                            : ''}
                                                    </div>
                                                    {toggleSchool ? errors.schoolId && touched.schoolId && (
                                                        <div className="" style={{ color: 'red' }}>{errors.schoolId}</div>
                                                    ) : errors.schoolName && touched.schoolName && (
                                                        <div className="" style={{ color: 'red' }}>{errors.schoolName}</div>)}
                                                    <div className="flex flex-wrap w-full">
                                                        <div className="w-6/12 pr-1">
                                                            <select onChange={this.handleChooseClass} value={values.grade} name="grade" className="pl-3 pr-3 h-11 mt-5 border-2 p-1 w-full rounded-md">
                                                                <option value="">Khối *</option>
                                                                <option value="6">Khối 6</option>
                                                                <option value="7">Khối 7</option>
                                                                <option value="8">Khối 8</option>
                                                                <option value="9">Khối 9</option>
                                                                <option value="10">Khối 10</option>
                                                                <option value="11">Khối 11</option>
                                                                <option value="12">Khối 12</option>
                                                            </select>
                                                            {errors.grade && touched.grade && (
                                                                <div className="" style={{ color: 'red' }}>{errors.grade}</div>
                                                            )}
                                                        </div>
                                                        <div className="w-6/12 pl-1">
                                                            <input
                                                                type="text"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.className}
                                                                name="className"
                                                                className="border-2 pl-3 pr-3 p-2 w-full mt-5 rounded-md"
                                                                placeholder="Lớp *"
                                                            />
                                                            {errors.className && touched.className && (
                                                                <div className="" style={{ color: 'red' }}>{errors.className}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-wrap mt-3">
                                                        <button onClick={handleSubmit} type="submit" className="mb-2 bg-blue-500 hover:bg-blue-700 text-white py-1 px-6 rounded-md mr-2">
                                                                        Gửi thông tin
                                                        </button>
                                                        <button onClick={this.handleCancel} type="button" className="mb-2 py-1 px-6 rounded-md border-2 mr-2">
                                                                        Nhập lại
                                                        </button>
                                                        <button type="button" onClick={this.toggleSchool} className="py-1 px-2 mb-2 xl:hidden block border border-green-400 text-green-400 rounded-md hover:bg-green-400 hover:text-white">
                                                                        Thêm trường
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
                <div hidden={isModalQuestion} className=" fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="inline-block w-11/12 sm:w-5/6 lg:w-10/12 mx-auto bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                            <div
                                className="justify-between p-3 border-b"
                            >
                                <p className="font-bold text-blue-500 text-center text-2xl">Chào mừng bạn tham gia cuộc thi</p>
                                <div className="mt-2 text-center mb-4">
                                    <i>Để hoàn thành đăng ký tham gia cuộc thi, bạn vui lòng trả lời các câu hỏi sau đây</i>
                                </div>
                                <div className="border-t-2 border-gray-500 pt-3">
                                    {twoQuestionArr.map((q, index) => (
                                        <div className="bg-gray-200 mb-2 rounded p-2 pl-4 mt-2">
                                            <div>
                                                        Câu
                                                {' '}
                                                {index + 1}
                                                        :
                                                {' '}
                                                {q.name}
                                            </div>
                                            <div className="ml-7">
                                                <div>
                                                    <label htmlFor>
                                                        <input type="checkbox" ref={`A${index}`} />
                                                        {' '}
                                                        {q.answerA}
                                                    </label>
                                                </div>
                                                <div>
                                                    <label htmlFor>
                                                        <input type="checkbox" ref={`B${index}`} />
                                                        {' '}
                                                        {q.answerB}
                                                    </label>
                                                </div>
                                                {q.answerC
                                                    ? (
                                                        <div>
                                                            <label htmlFor>
                                                                <input type="checkbox" ref={`C${index}`} />
                                                                {' '}
                                                                {q.answerC}
                                                            </label>
                                                        </div>
                                                    )
                                                    : ''}
                                                {q.answerD
                                                    ? (
                                                        <div>
                                                            <label htmlFor>
                                                                <input type="checkbox" ref={`D${index}`} />
                                                                {' '}
                                                                {q.answerD}
                                                            </label>
                                                        </div>
                                                    )
                                                    : ''}
                                                {q.answerE
                                                    ? (
                                                        <div>
                                                            <label htmlFor>
                                                                <input type="checkbox" ref={`E${index}`} />
                                                                {' '}
                                                                {q.answerE}
                                                            </label>
                                                        </div>
                                                    )
                                                    : ''}
                                                {q.hints
                                                    ? (
                                                        <div>
                                                                    Gợi ý:
                                                            {' '}
                                                            {q.hints}
                                                        </div>
                                                    )
                                                    : ''}
                                            </div>
                                        </div>
                                    ))}
                                    <div className="text-center mt-8 mb-5">
                                        <button type="button" onClick={this.handleAfterSignUp} className="px-5 py-3 bg-blue-500 rounded text-white">Hoàn thành đăng ký</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div hidden={modalForgotPass} className=" fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="inline-block w-11/12 sm:w-5/6 lg:w-5/6 xl:w-3/5 2xl:w-1/4 max-w-2xl mx-auto rounded-2xl bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                            <div
                                className={`${styles.headerSignin} ${'flex flex-row text-white justify-between p-3 border-b rounded-tl-lg rounded-tr-lg'}`}
                            >
                                <p className={`${styles.textLogin} ${'font-bold text-white'}`}>QUÊN MẬT KHẨU</p>
                                <svg xmlns="http://www.w3.org/2000/svg" onClick={this.toggleModal} className="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <Formik
                                initialValues={initialValuesForgotPass}
                                onSubmit={this.handleSubmitForgotPass}
                                validationSchema={validationSchemaForgotPass}
                                innerRef={this.refFormikForgotPass}
                            >
                                {props => {
                                    const {
                                        values,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        errors,
                                    } = props;
                                    return (
                                        <Form onSubmit={handleSubmit} className="p-5 bg-white flex justify-center flex-col shadow-md">
                                            <div className="flex flex-col px-1 py-5 rounded-br-2xl rounded-bl-2xl">
                                                <input
                                                    type="text"
                                                    name="username1"
                                                    value={values.username1}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`${styles.namePlaceholder} ${'w-full p-3 h-10 focus:border-blue-600 rounded-md border-2 outline-none'}`}
                                                    placeholder="Nhập tên đăng nhập *"
                                                    disabled={loading}
                                                />

                                                {errors.username1 && (
                                                    <div className="" style={{ color: 'red' }}>{errors.username1}</div>
                                                )}
                                                <button
                                                    disabled={loading}
                                                    className={`${styles.button} ${'mt-8 font-semibold text-white p-1.5 rounded-md w-full'}`}
                                                    onClick={handleSubmit}
                                                    type="submit"
                                                >
                                                    <span>Tiếp theo</span>
                                                </button>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
                <div hidden={isModalSyntax} className=" fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="inline-block w-11/12 sm:w-5/6 lg:w-1/4 max-w-2xl mx-auto rounded-2xl bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                            <div
                                className={`${styles.headerSignin} ${'flex flex-row text-white justify-between p-3 border-b rounded-tl-lg rounded-tr-lg'}`}
                            >
                                <p className={`${styles.textSignUp2} text-center font-bold text-white `}>
                                    {modal === 'a' ? 'ĐĂNG KÝ THÀNH CÔNG' : 'QUÊN MẬT KHẨU'}
                                </p>
                                <svg xmlns="http://www.w3.org/2000/svg" onClick={this.handleCloseSignUp} className="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            {modal === 'a'
                                ? (
                                    <div className="p-6">
                                        Chúc mừng bạn đã đăng ký thành công tài khoản
                                        {' '}
                                        <span className="text-green-500">{userName}</span>
                                        . Vui lòng đăng nhập để vào thi. Chúc bạn có trải nghiệm thú vị! ^^
                                    </div>
                                )
                                : modal === 'b'
                                    ? (
                                        <div className="p-6">
                                                    Bạn vui lòng gửi tin nhắn theo cú pháp:
                                            <div className="text-center">
                                                <span className="text-green-500">HSATTT</span>
                                                {' '}
                                                <span className="text-green-500">{userName}</span>
                                                {' '}
                                                        gửi đến Tổng đài
                                                {' '}
                                                <span className="text-green-500">8500</span>
                                            </div>
                                                    để gửi yêu cầu cấp lại mật khẩu nhé!
                                        </div>
                                    )
                                    : modal === 'c'
                                        ? (
                                            <div className="p-6">
                                                        Bạn vui lòng nhắn tin theo cú pháp:
                                                <br />
                                                <div className="text-center">
                                                    <span className="text-green-500">
                                                                HSATTT
                                                        {' '}
                                                        {userNameForgotPass}
                                                    </span>
                                                    {' '}
                                                            gửi đến
                                                    {' '}
                                                    <span className="text-green-500">8500</span>
                                                </div>
                                                        để lấy mật khẩu mới và xác thực tài khoản. Trân trọng cảm ơn
                                            </div>
                                        ) : (
                                            <div className="p-6">
                                                        Bạn vui lòng dùng số điện thoại
                                                {' '}
                                                <span className="text-green-500">{phoneForgotPass ? phoneForgotPass[0] : ''}</span>
                                                {' '}
                                                        nhắn tin theo cú pháp:
                                                <br />
                                                <div className="text-center">
                                                    <span className="text-green-500">
                                                                HSATTT
                                                        {' '}
                                                        {userNameForgotPass}
                                                    </span>
                                                    {' '}
                                                            gửi đến
                                                    {' '}
                                                    <span className="text-green-500">{phoneForgotPass ? phoneForgotPass[1] : 8500}</span>
                                                </div>
                                                        để lấy mật khẩu mới. Trân trọng cảm ơn
                                            </div>
                                        )}
                        </div>
                    </div>
                </div>
                <div hidden={addSchoolModal} className=" fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="inline-block w-11/12 sm:w-5/6 lg:w-1/4 max-w-2xl mx-auto rounded-2xl bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                            <div
                                className={`${styles.headerSignin} ${'flex flex-row text-white justify-between p-3 border-b rounded-tl-lg rounded-tr-lg'}`}
                            >
                                <p className={`${styles.textSignUp2} text-center font-bold text-white `}>
                                            THÊM TRƯỜNG
                                </p>
                                <svg xmlns="http://www.w3.org/2000/svg" onClick={this.handleCloseAddSchool} className="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <div className="px-1 py-2 text-center">
                                        Nếu không chọn được trường, bạn hãy gửi mail đến
                                {' '}
                                <a className="text-blue-500" href="mailto:hsattt@vnisa.org.vn">hsattt@vnisa.org.vn</a>
                                {' '}
                                        (hoặc qua zalo
                                {' '}
                                <a className="text-blue-500" href="tel:0981564086">{hotLine[0]?.value}</a>
                                        ) đề nghị thêm trường, ghi rõ: Tên trường và địa chỉ của trường cần thêm (Vd Trường THCS Nam Từ Liêm - Quận Nam Từ Liêm, Hà Nội)
                            </div>
                        </div>
                    </div>
                </div>

                <div hidden={isModalExamCode} className=" fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="inline-block w-11/12 sm:w-5/6 lg:w-5/6 xl:w-3/5 2xl:w-1/4 max-w-2xl mx-auto rounded-2xl bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                            <div
                                className={`${styles.headerSignin} ${'flex flex-row text-white justify-between p-3 border-b rounded-tl-lg rounded-tr-lg'}`}
                            >
                                <p className={`${styles.textLogin} ${'font-bold text-white'}`}>VÀO THI BẰNG MÃ CUỘC THI</p>
                                <svg xmlns="http://www.w3.org/2000/svg" onClick={this.toggleModalExamCode} className="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <Formik
                                initialValues={initialValuesCode}
                                onSubmit={this.handleCheckCode}
                                innerRef={this.refFormikCodeExam}
                                validationSchema={validationSchemaJoinExam}
                            >
                                {props => {
                                    const {
                                        values,
                                        errors,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = props;
                                    return (
                                        <Form onSubmit={handleSubmit} className="p-5 bg-white flex justify-center flex-col shadow-md">
                                            <div className="flex flex-col px-1 py-5 rounded-br-2xl rounded-bl-2xl">
                                                <input
                                                    type="text"
                                                    name="examCode"
                                                    id="examCode"
                                                    value={values.examCode}
                                                    onChange={handleChange}
                                                    disabled={+numberClick >= 5 || loading}
                                                    onBlur={handleBlur}
                                                    className={`${styles.namePlaceholder} ${'w-full p-3 h-10 focus:border-blue-600 rounded-md border-2 outline-none'}`}
                                                    placeholder="Nhập mã cuộc thi *"
                                                />
                                                {errors.examCode && (
                                                    <div className="" style={{ color: 'red' }}>{errors.examCode}</div>
                                                )}
                                                <button
                                                    disabled={+numberClick >= 5 || loading}
                                                    className={`${styles.button} ${'mt-8 font-semibold text-white p-1.5 rounded-md w-full'}`}
                                                    onClick={handleSubmit}
                                                    type="submit"
                                                >
                                                    <span>Tìm</span>
                                                </button>
                                                {seconds ? <div className="text-center mt-5" style={{ color: 'red' }}>{`Bạn đã nhập mã thi không tồn tại quá 5 lần vui lòng chờ ${time?.m ? `${time.m} phút` : ''} ${time?.s ? `${time.s} giây` : ''} để có thể tiếp tục nhập`}</div> : ''}
                                                {errorMessageExamCode && !seconds ? <div className="text-center mt-5" style={{ color: 'red' }}>{errorMessageExamCode}</div> : ''}
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>

                <div hidden={isModalAddInfomation} className=" fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="inline-block w-11/12 sm:w-5/6 lg:w-7/12 mx-auto rounded-2xl bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                            <div
                                className={`${styles.headerSignin} ${'flex flex-row text-white justify-between p-3 border-b rounded-tl-lg rounded-tr-lg '}`}
                            >
                                <div className={`${'font-bold text-white text-center w-full'}`}>BỔ SUNG THÔNG TIN HỌC SINH</div>
                                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({ isModalAddInfomation: true })} className="h-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <Formik
                                initialValues={initialValuesSignUpInfo}
                                onSubmit={this.handleSignUpInfoExam}
                                validationSchema={validationSchemaSignUpInfo}
                                innerRef={this.refFormikSignUpInfo}
                            >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = props;
                                    return (
                                        <Form onSubmit={handleSubmit} className="p-5 bg-white flex justify-center flex-col shadow-md">
                                            <div className="lg:flex lg:flex-wrap lg:justify-between">
                                                <div className="lg:w-6/12 pr-3">
                                                    <div className={`${styles.title} font-bold mt-2`}>Thông tin học sinh:</div>
                                                    <input
                                                        type="text"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.fullName}
                                                        name="fullName"
                                                        id="fullName"
                                                        className="border-2 pl-3 pr-3 p-2 w-full mt-5 rounded-md"
                                                        placeholder="Họ và Tên *"
                                                    />
                                                    {errors.fullName && touched.fullName && (
                                                        <div className="" style={{ color: 'red' }}>{errors.fullName}</div>
                                                    )}
                                                    <div className="flex flex-wrap w-full">
                                                        <div className="w-full md:w-6/12 md:pr-1">
                                                            <input
                                                                type="text"
                                                                onFocus={this.onFocus}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.birthOfDate}
                                                                name="birthOfDate"
                                                                id="birthOfDate"
                                                                className="border-2 pl-3 pr-3 p-2 w-full mt-5 rounded-md"
                                                                placeholder="Ngày sinh (dd/mm/yyyy)"
                                                            />
                                                            {errors.birthOfDate && touched.birthOfDate && (
                                                                <div className="" style={{ color: 'red' }}>{errors.birthOfDate}</div>
                                                            )}
                                                        </div>
                                                        <div className="w-full md:w-6/12 md:pl-1">
                                                            <input
                                                                type="phone"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.phone}
                                                                name="phone"
                                                                className="border-2 pl-3 pr-3 p-2 w-full mt-5 rounded-md"
                                                                placeholder="Số điện thoại liên hệ *"
                                                            />
                                                            {errors.phone && touched.phone && (
                                                                <div className="" style={{ color: 'red' }}>{errors.phone}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-wrap mt-8 lg-w-full ml-1">
                                                        <div className="lg:w-5/12 w-4/12">
                                                                        Giới tính:
                                                        </div>
                                                        <label className="flex lg:w-4/12 w-3/12" htmlFor="male">
                                                            <input
                                                                type="radio"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value="1"
                                                                name="gender"
                                                                id="male"
                                                                className="mt-2 mr-1"
                                                                defaultChecked={values.gender === 1}
                                                            />
                                                                        Nam
                                                        </label>
                                                        <label className="flex" htmlFor="female">
                                                            <input
                                                                type="radio"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value="2"
                                                                name="gender"
                                                                id="female"
                                                                className="mt-2 mr-1"
                                                                defaultChecked={values.gender === 2}
                                                            />
                                                                        Nữ
                                                        </label>
                                                    </div>

                                                    <div className="w-full lg:mt-8 mt-5 flex flex-wrap justify-between">
                                                        <select onChange={this.handleChooseSchool} value={values.schoolId} name="schoolId" className="pl-3 pr-3 h-11 border-2 p-1 rounded-md w-full xl:w-8/12" disabled={!values.districtId}>
                                                            <option value="">Trường *</option>
                                                            {schoolData.map((sc, index) => (
                                                                <option key={index} value={sc.id}>{sc.name}</option>
                                                            ))}
                                                        </select>
                                                        {values.schoolId === '' && values.districtId !== ''
                                                            ? (
                                                                <button type="button" onClick={this.toggleSchool} className="hidden xl:block w-4/12 border border-green-400 text-green-400 rounded-md hover:bg-green-400 hover:text-white">
                                                                                Thêm trường
                                                                </button>
                                                            )
                                                            : ''}
                                                    </div>
                                                    {toggleSchool ? errors.schoolId && touched.schoolId && (
                                                        <div className="" style={{ color: 'red' }}>{errors.schoolId}</div>
                                                    ) : errors.schoolName && touched.schoolName && (
                                                        <div className="" style={{ color: 'red' }}>{errors.schoolName}</div>)}
                                                </div>
                                                <div className="lg:w-6/12 pl-3">
                                                    <div className={`${styles.title} font-bold mt-2`}>Thông tin trường:</div>
                                                    <select onChange={this.handleChooseProvince} value={values.provinceId} name="provinceId" className="pl-3 pr-3 h-11 mt-5 border-2 p-1 w-full rounded-md">
                                                        <option value="">Tỉnh/Thành phố *</option>
                                                        {provinceData.map((pv, index) => (
                                                            <option key={index} value={pv.id}>{pv.name}</option>
                                                        ))}
                                                    </select>
                                                    {errors.provinceId && touched.provinceId && (
                                                        <div className="" style={{ color: 'red' }}>{errors.provinceId}</div>
                                                    )}
                                                    <select onChange={this.handleChooseDistrict} value={values.districtId} name="districtId" className="pl-3 pr-3 h-11 mt-5 border-2 p-1 w-full rounded-md">
                                                        <option value="">Quận/Huyện *</option>
                                                        {districtData.map((dt, index) => (
                                                            <option key={index} value={dt.id}>{dt.name}</option>
                                                        ))}
                                                    </select>
                                                    {errors.districtId && touched.districtId && (
                                                        <div className="" style={{ color: 'red' }}>{errors.districtId}</div>
                                                    )}

                                                    <div className="flex flex-wrap w-full">
                                                        <div className="w-6/12 pr-1">
                                                            <select onChange={this.handleChooseClass} value={values.grade} name="grade" className="pl-3 pr-3 h-11 mt-5 border-2 p-1 w-full rounded-md">
                                                                <option value="">Khối *</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                            </select>
                                                            {errors.grade && touched.grade && (
                                                                <div className="" style={{ color: 'red' }}>{errors.grade}</div>
                                                            )}
                                                        </div>
                                                        <div className="w-6/12 pl-1">
                                                            <input
                                                                type="text"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.className}
                                                                name="className"
                                                                className="border-2 pl-3 pr-3 p-2 w-full mt-5 rounded-md"
                                                                placeholder="Lớp *"
                                                            />
                                                            {errors.className && touched.className && (
                                                                <div className="" style={{ color: 'red' }}>{errors.className}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap mt-3">
                                                    <button onClick={handleSubmit} type="submit" className="mb-2 bg-blue-500 hover:bg-blue-700 text-white py-1 px-6 rounded-md mr-2">
                                                                        Gửi thông tin
                                                    </button>
                                                    <button onClick={this.handleCancel} type="button" className="mb-2 py-1 px-6 rounded-md border-2 mr-2">
                                                                        Nhập lại
                                                    </button>
                                                    <button type="button" onClick={this.toggleSchool} className="py-1 px-2 mb-2 xl:hidden block border border-green-400 text-green-400 rounded-md hover:bg-green-400 hover:text-white">
                                                                        Thêm trường
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>

                {this.renderChangePassWord()}
                {isNotiUserMax
                    ? (
                        <ToastContainer
                            position="top-center"
                            autoClose={4000}
                            hideProgressBar
                            newestOnTop={false}
                            bodyStyle={{ fontSize: 22 }}
                            style={{ width: 500 }}
                            closeOnClick
                            rtl={false}
                            pauseOnVisibilityChange
                            draggable
                            pauseOnHover
                        />
                    ) : (
                        <ToastContainer
                            position="bottom-right"
                            autoClose={3000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnVisibilityChange
                            draggable
                            pauseOnHover
                        />
                    )}

                <Loading
                    loading={loading}
                />
            </SWRConfig>
        );
    }
}
function mapStateToProps(state) {
    const { exam } = state;
    return {
        isAuthenticated: state.user.isAuthenticated,
        username: state.user.username,
        fullName: state.user.fullName,
        role: state.user.role,
        schoolName: state.user.schoolName,
        checkExam: exam?.checkExam,
    };
}
const LayoutWrapper = connect(mapStateToProps)(Layout);
export const getLayout = page => <LayoutWrapper>{page}</LayoutWrapper>;
export default withRouter(LayoutWrapper);
