export const updateCurrentUnitId = (unitId) => ({
    type: 'UPDATE_CURRENT_UNITID',
    payload: unitId,
});

export const updateUsername = (username) => ({
    type: 'UPDATE_USERNAME',
    payload: username,
});

export const updateUnitId = (unitId) => ({
    type: 'UPDATE_UNITID',
    payload: unitId,
});

export const updateDataUser = (userInfo) => ({
    type: 'UPDATE_USERINFO',
    payload: userInfo,
});

export const signOut = () => ({
    type: 'SIGN_OUT',
});

export const signIn = () => ({
    type: 'SIGN_IN',
});