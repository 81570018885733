import Cookies from 'js-cookie';
import * as FileSaver from 'file-saver';
import { toast } from 'react-toastify';
import Router from 'next/router';

const axios = require('axios');

const { CancelToken } = axios;

const client = axios;

const handleSignOut = () => {
    Cookies.remove('jwt', { path: '/' });
    Router.push('/');
};

export const makeRequestGet = (url, params) => {
    const token = Cookies.get('jwt');

    let cancel;
    const promise = new Promise((resolve, reject) => {
        client({
            method: 'get',
            url,
            params,
            headers: token && {
                Authorization: `Bearer ${token}`,
                'Accept-Language': 'vi',
            },
            cancelToken: new CancelToken((c) => {
                cancel = c;
            }),
        }).then(res => {
            resolve(res);
        }).catch(err => {
            if (err?.response?.status === 401) {
                toast.error('Phiên đăng nhập đã hết hạn hoặc tài khoản đã đăng nhập trên thiết bị khác. Vui lòng đăng nhập lại');
                setTimeout(
                    () => {
                        handleSignOut();
                    },
                    3000,
                );
            } else {
                reject(err);
            }
        });
    });
    promise.cancel = cancel;
    return promise;
};
export const makeRequestGetNoMasterUnitId = (url, params) => {
    const token = Cookies.get('jwt');
    let cancel;
    const promise = new Promise((resolve, reject) => {
        client({
            method: 'get',
            url,
            params,
            headers: token && {
                Authorization: `Bearer ${token}`,
                'Accept-Language': 'vi',
            },
            cancelToken: new CancelToken((c) => {
                cancel = c;
            }),
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });
    });
    promise.cancel = cancel;
    return promise;
};

export const makeRequestPost = (url, params, data) => {
    const token = Cookies.get('jwt');

    let cancel;
    const promise = new Promise((resolve, reject) => {
        client({
            method: 'post',
            url,
            data,
            params,
            headers: token && {
                Authorization: `Bearer ${token}`,
                'Accept-Language': 'vi',

            },
            cancelToken: new CancelToken((c) => {
                cancel = c;
            }),
        }).then(res => {
            resolve(res);
        }).catch(err => {
            if (err?.response?.status === 401) {
                toast.error('Phiên đăng nhập đã hết hạn hoặc tài khoản đã đăng nhập trên thiết bị khác. Vui lòng đăng nhập lại');
                setTimeout(
                    () => {
                        handleSignOut();
                    },
                    3000,
                );
            } else {
                reject(err);
            }
        });
    });

    promise.cancel = cancel;
    return promise;
};

export const makeRequestPostExcel = (url, params, data) => {
    const token = Cookies.get('jwt');

    let cancel;
    const promise = new Promise((resolve, reject) => {
        client({
            method: 'post',
            url,
            data,
            params,
            headers: token && {
                Authorization: `Bearer ${token}`,
                'Accept-Language': 'vi',

            },
            cancelToken: new CancelToken((c) => {
                cancel = c;
            }),
            responseType: 'blob',
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });
    });

    promise.cancel = cancel;
    return promise;
};

export const makeRequestGetDownloadFileWord = (url, params) => {
    const token = Cookies.get('jwt');

    let cancel;
    const promise = new Promise((resolve, reject) => {
        client({
            method: 'get',
            url,
            params,
            headers: token && {
                Authorization: `Bearer ${token}`,
                'Accept-Language': 'vi',
            },
            cancelToken: new CancelToken((c) => {
                cancel = c;
            }),
            responseType: 'blob',
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });
    });
    promise.cancel = cancel;
    return promise;
};

export const makeRequestGetDownloadFileExcel = (url, params) => {
    const token = Cookies.get('jwt');

    let cancel;
    const promise = new Promise((resolve, reject) => {
        client({
            method: 'get',
            url,
            params,
            headers: token && {
                Authorization: `Bearer ${token}`,
                'Accept-Language': 'vi',
            },
            cancelToken: new CancelToken((c) => {
                cancel = c;
            }),
            responseType: 'blob',
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });
    });
    promise.cancel = cancel;
    return promise;
};

export const makeRequestPostNoMasterUnitId = (url, params, data) => {
    const token = Cookies.get('jwt');
    let cancel;
    const promise = new Promise((resolve, reject) => {
        client({
            method: 'post',
            url,
            data,
            params,
            headers: token && {
                Authorization: `Bearer ${token}`,
                'Accept-Language': 'vi',

            },
            cancelToken: new CancelToken((c) => {
                cancel = c;
            }),
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });
    });

    promise.cancel = cancel;
    return promise;
};

export const makeRequestPut = (url, params, data) => {
    const token = Cookies.get('jwt');

    let cancel;
    const promise = new Promise((resolve, reject) => {
        client({
            method: 'put',
            url,
            params,
            data,
            headers: token && {
                Authorization: `Bearer ${token}`,
                'Accept-Language': 'vi',
            },
            cancelToken: new CancelToken((c) => {
                cancel = c;
            }),
        }).then(res => {
            resolve(res);
        }).catch(err => {
            if (err?.response?.status === 401) {
                toast.error('Phiên đăng nhập đã hết hạn hoặc tài khoản đã đăng nhập trên thiết bị khác. Vui lòng đăng nhập lại');
                setTimeout(
                    () => {
                        handleSignOut();
                    },
                    3000,
                );
            } else {
                reject(err);
            }
        });
    });

    promise.cancel = cancel;
    return promise;
};
export const makeRequestPutNoMasterUnitId = (url, params, data) => {
    const token = Cookies.get('jwt');
    let cancel;
    const promise = new Promise((resolve, reject) => {
        client({
            method: 'put',
            url,
            params,
            data,
            headers: token && {
                Authorization: `Bearer ${token}`,
                'Accept-Language': 'vi',

            },
            cancelToken: new CancelToken((c) => {
                cancel = c;
            }),
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });
    });

    promise.cancel = cancel;
    return promise;
};

export const makeRequestDelete = (url, params) => {
    const token = Cookies.get('jwt');

    let cancel;
    const promise = new Promise((resolve, reject) => {
        client({
            method: 'delete',
            url,
            params,
            headers: token && {
                Authorization: `Bearer ${token}`,
                'Accept-Language': 'vi',
            },
            cancelToken: new CancelToken((c) => {
                cancel = c;
            }),
        }).then(res => {
            resolve(res);
        }).catch(err => {
            if (err?.response?.status === 401) {
                toast.error('Phiên đăng nhập đã hết hạn hoặc tài khoản đã đăng nhập trên thiết bị khác. Vui lòng đăng nhập lại');
                setTimeout(
                    () => {
                        handleSignOut();
                    },
                    3000,
                );
            } else {
                reject(err);
            }
        });
    });
    promise.cancel = cancel;
    return promise;
};

export const makeRequestDeleteNoMasterUnitId = (url, params) => {
    const token = Cookies.get('jwt');
    let cancel;
    const promise = new Promise((resolve, reject) => {
        client({
            method: 'delete',
            url,
            params,
            headers: token && {
                Authorization: `Bearer ${token}`,
                'Accept-Language': 'vi',

            },
            cancelToken: new CancelToken((c) => {
                cancel = c;
            }),
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });
    });
    promise.cancel = cancel;
    return promise;
};
