import React, { Component } from 'react';
import { connect } from 'react-redux';

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { loading } = this.props;
        return (
            <div hidden={!loading} className=" fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <div className="inline-block w-11/12 sm:w-5/6 lg:w-5/6 xl:w-3/5 2xl:w-1/4 max-w-2xl mx-auto rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                        <div className="p-5 flex justify-center flex-col shadow-md">
                            <div className="flex flex-col px-1 py-5 rounded-br-2xl rounded-bl-2xl">
                                <div className="flex items-center justify-center col-start-2 row-start-2">
                                    <div className="flex items-center justify-center space-x-2 animate-pulse">
                                        <div className="w-8 h-8 bg-blue-400 rounded-full" />
                                        <div className="w-8 h-8 bg-blue-400 rounded-full" />
                                        <div className="w-8 h-8 bg-blue-400 rounded-full" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

export default connect(mapStateToProps)(Loading);